import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PreLoanFormV3 from './PreLoanForm';
import { useQuery } from '../../../utils/helpers';
import ErrorFallback, { errorHandler } from '../../../components/ErrorFallback';
import PLRWelcome from '../PLRWelcome';

type PreLoanRequestV3Props = {
    preLoanId: string,
    showForm: boolean,
    handleShowForm: (show:boolean)=> void,
    origin: {originLabel: string, partner: string, partnerProducts: string[]}
}

export default function PreLoanRequestIndexV3(props:PreLoanRequestV3Props) {
  const {
    preLoanId, showForm, handleShowForm, origin,
  } = props;
  const query = useQuery();
  const [preLoanRut, setPreLoanRut] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [periods, setPeriods] = React.useState('');
  const [installment, setInstallment] = React.useState('');
  const [referralCode, setReferralCode] = React.useState<string|null>(null);
  const [optIn, setOptIn] = React.useState(false);

  const phone = query.get('phone');

  React.useEffect(() => {
    const rut = query.get('rut');
    const amountData = query.get('amount');
    const periodsData = query.get('periods');
    const installmentData = query.get('installment');
    const referralCodeData = query.get('referralCode');
    const optInData = query.get('optIn');
    setAmount(amountData || '');
    setPeriods(periodsData || '');
    setInstallment(installmentData || '');
    setPreLoanRut(rut || '');
    setReferralCode(referralCodeData);
    setOptIn(optInData === 'true');
  }, [query]);

  return (
    showForm ? (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={
              (err: Error, info:{ componentStack: string }) => errorHandler(
                err,
                info,
                undefined,
                undefined,
              )
          }
        onReset={() => handleShowForm(false)}
      >
        <PreLoanFormV3
          preLoanRut={preLoanRut}
          phone={phone || '+56'}
          preLoanRequestId={preLoanId}
          origin={origin}
          amount={amount}
          periods={periods}
          installment={installment}
          referralCode={referralCode}
          optIn={optIn}
        />
      </ErrorBoundary>
    ) : (
      <PLRWelcome
        amount={amount}
        periods={periods}
        handleSetShowForm={handleShowForm}
        origin={origin}
      />
    )

  );
}
