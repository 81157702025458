import React from 'react';
import {
  CircularProgress, Typography, Grid, Paper, useTheme,
} from '@mui/material';
import accountStyles from '../account/styles';
import bancameLogo from '../../assets/bancame_logo.svg';
import { useQuery } from '../../utils/helpers';
import { termsAndConditionsPoints, termsAndConditionsPartners } from './helpers';
import { patchOriginClick } from '../../queries/preloan/v3';

type TermsAndConditionsPropsType = {
    id: number,
    title: string,
    content: {
        id: number,
        content: string,
    }[],
};

export default function TermsAndConditions() {
  const query = useQuery();
  const classes = accountStyles();
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [origin, setOrigin] = React.useState({
    originLabel: 'web',
    partner: 'Banca.Me',
    partnerProducts: ['consumer, microLoan'],
  });

  const getOrigin = async () => {
    const originLabel = query.get('origin') || 'web';
    setLoading(true);
    try {
      const res = await patchOriginClick(originLabel, false);
      setLoading(false);
      return setOrigin(res.data);
    } catch (e) {
      setLoading(false);
      return setOrigin(origin);
    }
  };

  React.useEffect(() => { getOrigin(); }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: theme.palette.primary.main }}
    >

      <Paper className={classes.paper} elevation={1} style={{ borderRadius: '10px', marginTop: '90px' }}>
        <div>
          <img src={bancameLogo} style={{ height: '55px' }} alt="BancameLogo" />
          {loading
            ? (
              <div style={{ margin: 40 }}>
                <CircularProgress size={50} />
                <Typography
                  height="100vh"
                  width="60vh"
                  gutterBottom
                  variant="h6"
                >
                  Cargando...

                </Typography>
              </div>
            )
            : (
              <>
                <Typography fontWeight="bold" align="left" variant="h5" component="h4" style={{ marginTop: '10px' }}>
                  Términos y Condiciones de Bancame Spa
                </Typography>
                <div>
                  {origin && origin.partnerProducts.some((prod) => prod === 'mortgage')
                    ? termsAndConditionsPartners(origin.originLabel)
                      .map((tycp: TermsAndConditionsPropsType) => (
                        <div>
                          <br />
                          <Typography
                            align="left"
                            variant="h6"
                            sx={{ lg: { maxWidth: '100%' } }}
                          >
                            <b>{tycp.title}</b>
                            {tycp.content.map((content) => {
                              const textNodes = content.content.split(/(<b>|<\/b>)/g);

                              return (
                                <div>
                                  <br />
                                  <Typography
                                    align="justify"
                                    variant="body1"
                                    sx={{ lg: { maxWidth: '80%' } }}
                                  >
                                    <>
                                      {textNodes.map((text, index) => {
                                        if (text === '<b>') {
                                          const html = <strong>{textNodes[index + 1]}</strong>;
                                          textNodes.splice(index + 1, 1);
                                          return html;
                                        }
                                        if (text === '</b>') {
                                          return null;
                                        }
                                        return <span>{text}</span>;
                                      })}
                                    </>
                                  </Typography>
                                </div>
                              );
                            })}
                          </Typography>
                        </div>
                      ))
                    : termsAndConditionsPoints.map((tycp) => (
                      <div>
                        <br />
                        <Typography
                          align="left"
                          variant="h6"
                          sx={{ lg: { maxWidth: '100%' } }}
                        >
                          <b>{tycp.title}</b>
                          {tycp.content.map((content) => {
                            const textNodes = content.content.split(/(<b>|<\/b>)/g);

                            return (
                              <div>
                                <br />
                                <Typography
                                  align="justify"
                                  variant="body1"
                                  sx={{ lg: { maxWidth: '80%' } }}
                                >
                                  <>
                                    {textNodes.map((text, index) => {
                                      if (text === '<b>') {
                                        const html = <strong>{textNodes[index + 1]}</strong>;
                                        textNodes.splice(index + 1, 1);
                                        return html;
                                      }
                                      if (text === '</b>') {
                                        return null;
                                      }
                                      return <span>{text}</span>;
                                    })}
                                  </>
                                </Typography>
                              </div>
                            );
                          })}
                        </Typography>
                      </div>
                    ))}
                </div>
              </>
            )}
        </div>

      </Paper>
    </Grid>
  );
}
