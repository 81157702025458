/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import type { FormikProps } from 'formik';
import type { FormSchemaV3 } from '../../../../types/preloan/v3';
import RejectedForm from './steps/00_rejected';
import RutForm from './steps/01_rut';
import DataFetchingForm from './steps/02_dataFetching';
import ClaveUnicaForm from './steps/03_0_ClaveUnica';
import UploadDocumentsForm from './steps/03_1_UploadDocuments';
import FillForm from './steps/03_2_FillForm';
import NeedsICPForm from './steps/03_3_NeedsICP';
import LoanSimulationForm from './steps/04_LoanSimulation';
import CompanyInfoForm from './steps/05_companyInfo';
import LoanConfirmationForm from './steps/06_loanConfirmation';
import { patchPLRStep } from '../../../../queries/preloan/v2';

export const formSchema: FormSchemaV3 = {
  product: 'PLR V3',
  origin: '',
  PLRtoken: '',
  id: '',
  step: '',
  rut: '',
  captchaToken: '',
  firstName: '',
  lastName: '',
  email: '',
  rejectReason: '',
  referralCode: null,
  results: undefined,
  companyName: '',
  companyContactName: '',
  companyContactPhone: '+56 ',
  companyContactMail: '',
  requestedAmount: 0,
  requestedPeriods: 0,
  requestedInstallment: 0,
  loanParams: {
    canGetLoan: true,
    alpha: -0.11764706,
    beta: 0.25235294,
    payProb: {
      3: 0.72,
      6: 0.70,
      9: 0.68,
      12: 0.65,
      15: 0.60,
      18: 0.55,
      24: 0.50,
      36: 0.45,
    },
    originCost: 10000,
    riskEngineMaxAmount: 1500000,
    maxInstallmentAmount: 500000,
    riskEngineMaxPeriod: 36,
    basePaymentMethod: 'pac',
    preApproveId: '',
    productType: 'consumer',
    amountStepSize: 25000,
    insurances: [],
    minAmount: 25000,
    onlyStepSize: false,
    paymentDate: 1,
    currency: { abbreviation: 'CLP', precision: 0 },
  },
  requestLoanData: {
    amount: 0,
    interestRate: 0,
    periods: 0,
    installment: 0,
    totalCost: 0,
    monthlyCAE: 0,
    CAE: 0,
    originCost: 0,
    taxes: 0,
    insuranceCost: 0,
  },
  adminComment: '',
  needsSettlement: true,
  needsPensionStatement: false,
  liqFile: undefined,
  cotizFile: undefined,
  personalInfo: {
    firstName: '',
    lastName: '',
    birthDate: null,
    sex: '',
  },
  userInput: {
    workStatus: '',
    educationLevel: '',
    gigworkerMotivation: '',
    houseIncome: '',
    economicDependents: '',
    civilStatus: '',
    loanPurpose: '',
  },
  debts: undefined,
  dataForm: {
    age: { value: '', type: 'number' },
    sex: { value: '', type: 'string' },
    brute_salary: { value: '', type: 'number' },
    net_salary: { value: '', type: 'number' },
    emp_length: { value: '', type: 'number' },
    type_of_contract: { value: '', type: 'string' },
    licenses: { value: '', type: 'number' },
  },
  contactForm: {
    email: { value: '', type: 'Email', ok: true },
    phone: { value: '+56 ', type: 'Phone', ok: true },
  },
  optIn: false,
  preApprove: {
    accepted: false,
    id: '',
    maxAmount: '0',
    maxInstallmentAmount: 0,
    maxPeriods: 0,
    productType: 'consumer',
    rate: 0,
    currency: {
      abbreviation: 'CLP',
      precision: 0,
    },
  },
  partner: 'Banca.me',
  partnerProducts: ['consumer', 'microLoan'],
  serialNumber: '',
  experianChallenge: {
    data: {
      RESUMEN: {
        RUT: '',
        USUARIO: '',
        IDCHALLENGE: '',
      },
      PREGUNTAS: [],
      codigoRetorno: '',
    },
    success: false,
    errors: [],
  },
  experianChallengeResponses: {},
  experianChallengeResult: { success: false, errors: [] },
  experianRetries: 0,
};

export const stepperSteps = ['Te damos la bienvenida a Banca.Me',
  '¿Cuál es tu RUT?',
  'Contacto empleador',
  'Simulación',
  'Cierre'];

export const steps = [
  '¿Cuál es tu RUT?',
  'Consulta de datos',
  'Acceso con ClaveÚnica',
  'Form',
  'Acceso Previred',
  'Contacto empleador',
  'Simulación',
  'Confirmación',
  'Cierre'];

export const getStepContent = (
  step: number,
  currentData: FormSchemaV3,
  refsObject: {
    RejectedFormRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    RutFormRef:React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    DataFetchingFormRef:React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    UploadDocumentsFormRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    FillFormRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    CompanyInfoFormRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    LoanSimulationFormRef:React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    LoanConfirmationRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    NeedsICPRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
  },
  handleCurrentData: (data:FormSchemaV3)=> void,
  handleNext: ()=> void,
  handleStep: (st: number)=> void,
  handleEnableLoading: (state:boolean)=> void,
) => {
  const componentProps = {
    formSchema: currentData,
    handleNext,
    handleStep,
    handleEnableLoading,
    handleCurrentData,
  };
  switch (step) {
    case -1:
      return (
        <RejectedForm
          formRef={refsObject.RejectedFormRef}
          {...componentProps}
        />
      );
    case 0:
      return (
        <RutForm
          formRef={refsObject.RutFormRef}
          {...componentProps}
        />
      );
    case 1:
      return (
        <DataFetchingForm
          formRef={refsObject.DataFetchingFormRef}
          {...componentProps}
        />
      );
    case 2:
      return (
        <ClaveUnicaForm
          formRef={refsObject.DataFetchingFormRef}
          {...componentProps}
        />
      );
    case 3:
      return (
        <UploadDocumentsForm
          formRef={refsObject.UploadDocumentsFormRef}
          {...componentProps}
        />
      );
    case 4:
      return (
        <FillForm
          formRef={refsObject.FillFormRef}
          {...componentProps}
        />
      );
    case 5:
      return (
        <LoanSimulationForm
          formRef={refsObject.LoanSimulationFormRef}
          {...componentProps}
        />
      );
    case 6:
      return (
        <CompanyInfoForm
          formRef={refsObject.CompanyInfoFormRef}
          {...componentProps}
        />
      );
    case 7:
      return (
        <LoanConfirmationForm
          formRef={refsObject.LoanConfirmationRef}
          {...componentProps}
        />
      );
    case 8:
      return (
        <NeedsICPForm
          formRef={refsObject.NeedsICPRef}
          {...componentProps}
        />
      );
    default:
      return <div />;
  }
};

export const getOriginButtonLabel = (origin: string) => {
  switch (origin) {
    case 'bricsa':
      return 'Ir a Bricsa';
    case 'tricapitals':
      return 'Ir a Tricapitals';
    case 'ideal':
      return 'Ir a I-Deal';
    case 'fundamenta':
      return 'Ir a Fundamenta';
    case 'numancia':
      return 'Ir a Numancia';
    case 'crillon':
      return 'Ir a Crillon';
    case 'altura':
      return 'Ir a Altura';
    case 'andes':
      return 'Ir a Inmobiliaria Andes';
    case 'puelo':
      return 'Ir a Puelo Gestión';
    case 'besalco':
      return 'Ir a Besalco';
    case 'gpr':
      return 'Ir a GPR';
    case 'euro':
      return 'Ir a Euro';
    case 'urmeneta':
      return 'Ir a Urmeneta';
    case 'nash':
      return 'Ir a Nash';
    case 'goplaceit':
      return 'Ir a Capitalizarme';
    case 'buenaventura':
      return 'Ir a Inmobiliaria Buenaventura';
    case 'fai':
      return 'Ir a Inmobiliaria Fai';
    case 'icafal':
      return 'Ir a Icafal';
    case 'conquista':
      return 'Ir a Conquista';
    case 'ecasa':
      return 'Ir a Ecasa';
    case 'buyDepa':
      return 'Ir a Buydepa';
    case 'rentaCapital':
      return 'Ir a Renta Capital';
    case 'brick':
      return 'Ir a Brick';
    case 'vimac':
      return 'Ir a Vimac';
    case 'siena':
      return 'Ir a Siena';
    case 'libercoop':
      return 'Ir a Libercoop';
    case 'manquehue':
      return 'Ir a Manquehue';
    case 'portalInmobiliario':
      return 'Ir a Portal Inmobiliario';
    case 'norteVerde':
      return 'Ir a Norte Verde';
    case 'indesa':
      return 'Ir a Indesa';
    case 'tenpo':
      return 'Volver a Tenpo';
    default:
      return 'Ir a Banca.Me';
  }
};
export const getButtonLabel = (step: number, origin:string) => {
  if ([steps.length, -1].includes(step)) {
    return getOriginButtonLabel(origin);
  }
  return 'Continuar';
};

export function partnerErrorRedirectURL(originLabel: string, idNumber: string) {
  const url = window.location.host;
  switch (originLabel) {
    case 'tenpo':
      return 'https://tenpo.cl/?bancame_error';
    case 'brincus':
      return (url.includes('dev') || url.includes('localhost') || url.includes('beta'))
        ? `https://www.brincus.com/dev/cuenta/bancame_fallo?rut=${idNumber}`
        : `https://home.brincus.com/cuenta/bancame_fallo?rut=${idNumber}`;
    default:
      return undefined;
  }
}

export const archiveUnemployedPLR = async (
  inputFormSchema: FormSchemaV3,
  handleCurrentData: (data: FormSchemaV3) => void,
  handleStep: (step: number) => void,
) => {
  const rejectReason = 'La persona solicitante indica que no posee un contrato de trabajo vigente.';
  await patchPLRStep('ARCHIVED_FAIL', [rejectReason], inputFormSchema.PLRtoken);

  handleCurrentData({
    ...inputFormSchema,
    adminComment: `RECHAZO AUTOMÁTICO: ${rejectReason}`,
  });
  window.dataLayer.push({
    event: 'Usuario rechazado',
    paso: 'Datos complementarios',
    subpaso: 'Completar formulario',
    motivo: `RECHAZO AUTOMÁTICO: ${rejectReason}`,
  });
  return handleStep(-1);
};
