type TermsAndConditionsType = {
    id: number,
    title: string,
    content: {
        id: number,
        content: string,
    }[],
}[];

export const termsAndConditionsPoints: TermsAndConditionsType = [
  {
    id: 2,
    title: 'Introducción',
    content: [
      {
        id: 21,
        content: 'Esta política de privacidad describe los datos personales recopilados o generados (tratados) cuando usted interactúa con la página web de propiedad de la sociedad <b> Bancame SpA (creditos.banca.me/preloan) - (la “Página Web”)</b>. Asimismo, esta política describe los tipos de datos personales recopilados y cómo se utilizan, comparten y protegen. También explica las opciones que tiene en relación con sus datos personales y cómo puede ponerse en contacto con nosotros.',
      },
      {
        id: 22,
        content: 'En este sentido, se entenderá que al ingresar tu cédula de identidad o rol único tributario y presionar la casilla “continuar” de la Página Web, usted reconoce y declara haber leído, comprendido y aceptado íntegramente el contenido del presente instrumento en todas sus partes.',
      },
    ],
  },
  {
    id: 3,
    title: '¿Quién es responsable del procesamiento de sus datos personales?',
    content: [
      {
        id: 31,
        content: 'Cuando se interactúe con la Página Web o se ingresen tus datos para efectos de recibir los servicios de la empresa “Bancame SpA”, con el objeto de postular a una evaluación de tus antecedentes financieros, para una simulación de un crédito, tus datos personales serán procesados por Bancame SpA (“Bancame”)',
      },
      {
        id: 32,
        content: 'Cuando se registre una cuenta y/o ingrese con su cuenta en la Página Web, Bancame procederá a procesar tus datos personales, acorde a la presente Política y a la legislación y regulación aplicable.',
      },
      {
        id: 33,
        content: 'Esta Política describe la manera en que Bancame procesará tus datos y como las Partes harán uso y protegerán tus datos personales. Bancame será responsable por el procesamiento de sus datos personales. Las Partes serán responsables de la protección de los datos personales y el uso que le otorguen.',
      },
    ],
  },
  {
    id: 4,
    title: '¿Qué datos personales recolectamos y cuándo?',
    content: [
      {
        id: 41,
        content: 'Para proporcionarle los servicios que solicite, le pediremos ciertos datos personales. Por ejemplo, cuando cree una cuenta o utilice la Página Web, para ser sujeto de evaluación para optar a un crédito hipotecario.',
      },
      {
        id: 42,
        content: 'Adicionalmente, cuando proporcione datos personales, deberá confirmar que la información entregada es verdadera, precisa y actualizada. De igual manera, usted consentirá en informar a Bancame, de cualquier corrección, actualización o ajuste, que nosotros debemos implementar a los datos personales proporcionados, contactándonos al siguiente correo electrónico info@banca.me.',
      },
      {
        id: 43,
        content: 'Los datos personales que les solicitaremos son los siguientes: /i/ su rol único tributario, para efectos de recopilar información bancaria y financiera en instituciones como Transunion, Proriesgo, Sinacofi, Comisión para el Mercado Financiero, Sencillito y Servipag; y, /ii/ su Clave Única del Servicio del Registro Civil e Identificación y los datos allí contenidos, tales como certificado de cotizaciones, última renta imponible, empleador, tipo de contrato, antigüedad laboral, créditos con cajas de compensación, fecha de nacimiento, nombre, sexo, hijos, defunción, mail, teléfono, informe de deudas, monto total deudas activas por banco e instituciones financieras, y monto total deudas morosas por banco e instituciones financieras, préstamo solidario y domicilio.',
      },
      {
        id: 44,
        content: 'Al interactuar con la Página Web, algunos datos se recopilan automáticamente desde su dispositivo o navegador web.',
      },
    ],
  },
  {
    id: 5,
    title: 'Menores de edad.',
    content: [
      {
        id: 51,
        content: 'Cumplimos con las leyes locales y no permitimos que los menores de edad se registren en la Página Web, cuando no alcancen la edad legal mínima requerida en el país en el que residen.',
      },
    ],
  },
  {
    id: 6,
    title: 'Herramientas para administrar los datos personales que recolectamos.',
    content: [
      {
        id: 61,
        content: 'Al utilizar la Página Web, también le informamos sobre nuestra recopilación u obtenemos consentimiento para ciertas prácticas. Se obtendrá su consentimiento a través de la Página Web.',
      },
      {
        id: 62,
        content: 'Adicionalmente, la verificación de la identidad del Usuario en el Sitio, se hará a través de un servicio externo denominado Toc Biometrics, lo que el Usuario autoriza expresamente, y que consiste en una verificación de identidad biométrico facial que opera con cámara digital, que permite la lectura y comparación del rostro con la información contenida en el documento de identidad del Usuario. Este sistema de verificación de identidad retorna como resultado la generación de un token único de transacción, que corresponde a un valor alfanumérico e identifica la verificación realizada. Asimismo, este sistema generará un documento en formato pdf. con firma electrónica TOC y sellado digital en la contratación de los Servicios.',
      },
    ],
  },
  {
    id: 7,
    title: '¿Por qué y cómo utilizamos (tratamos) sus datos personales?',
    content: [
      {
        id: 71,
        content: 'i. Para proporcionar las funciones de la plataforma y servicios que solicita.Cuando utilice la Página Web, utilizaremos sus datos para proporcionarle un servicio de evaluación de sus antecedentes financieros para una simulación de un crédito.',
      },
      {
        id: 72,
        content: 'ii. Para comunicar información sobre productos, servicios, y para otros objetivos promocionales, de parte de las Partes. Cuando brinde su consentimiento en la Página Web, Bancame y podrá enviar comunicaciones publicitarias y noticias sobre sus productos, servicios, y otras promociones. Puede darse de baja en cualquier momento después de haber dado su consentimiento y toda comunicación comercial describirá las formas de contacto, para solicitar la suspensión de estos mensajes.',
      },
      {
        id: 73,
        content: 'Si usted es un cliente actual de Bancame (por ejemplo, si ya ha contratado los servicios en la Página Web), también podremos utilizar la dirección de correo electrónico proporcionada para enviarle comunicaciones de marketing sobre productos o servicios similares de Bancame, cuando esté permitido por la ley aplicable (salvo que haya renunciado a ello). En otros casos, le solicitaremos su consentimiento para enviarle información de marketing.',
      },
      {
        id: 74,
        content: 'iv. Para proteger la propiedad, seguridad o derechos propios o de terceros. También es posible que Bancame utilice sus datos personales, acerca de cómo usted utiliza la Página Web, para prevenir o detectar abusos, usos ilegales e infracciones de nuestros Términos de Uso y para cumplir con órdenes judiciales, solicitudes gubernamentales o leyes aplicables.',
      },
      {
        id: 75,
        content: 'v. Para objetivos generales de investigación y análisis Las Partes utilizarán los datos acerca de las personas que ingresen a la Página Web y servicios para comprender el comportamiento o las preferencias de los clientes.',
      },
      {
        id: 76,
        content: 'vi. Otros usos. También es posible que se utilice su información personal en otras maneras y en ese caso, lo notificaremos específicamente al momento de recopilar la información y obtener el consentimiento que fuere necesario.',
      },
      {
        id: 77,
        content: 'vii. Fundamentos Legales Como se señaló anteriormente, Bancame será aquella quien procesará sus datos personales. Ahora bien, para procesar sus datos personales, nos basamos en ciertos fundamentos legales, según cómo interactúe con la Página Web. Por lo anterior, nuestra base legal será el consentimiento, como es requerido ante la legislación local.',
      },
      {
        id: 78,
        content: 'También nos basamos en otros fundamentos legales, como nuestros intereses legítimos como empresa, para cumplir con una obligación legal.',
      },
    ],
  },
  {
    id: 8,
    title: 'Transmisión de sus datos personales.',
    content: [
      {
        id: 81,
        content: 'Bancame comparte sus datos personales con:',
      },
      {
        id: 82,
        content: 'i. Terceros en la medida que fuere necesaria para: (i) cumplir con una solicitud del gobierno, una orden judicial o ley aplicable; (ii) prevenir usos ilegales de la Página Web o violaciones de los Términos de Uso y las políticas de la Página Web; (iii) defender a Bancame contra reclamaciones de terceros; y (iv) ayudar en la prevención o investigación de fraude (por ejemplo, falsificación).',
      },
      {
        id: 83,
        content: 'ii. A cualquier otro tercero cuando se lo hayamos comunicado y usted haya prestado consentimiento.',
      },
      {
        id: 84,
        content: 'iii. También podemos transferir los datos personales que Bancame tenga sobre usted, en caso de que vendamos o transfiramos la totalidad o una parte de nuestro negocio o activos (incluyendo en caso de reorganización, escisión, disolución o liquidación).',
      },
    ],
  },
  {
    id: 9,
    title: 'Protección y administración de sus datos personales.',
    content: [
      {
        id: 91,
        content: '<b>Encriptación y Seguridad.</b> Encriptación y Seguridad En el procesamiento de datos, Bancame cuenta con una variedad de medidas de seguridad técnica y organizativa, incluyendo herramientas de encriptación y autenticación, para mantener la seguridad de sus datos personales.',
      },
      {
        id: 92,
        content: '<b>Transferencias internacionales de datos.</b> Los datos personales que se recopilan o se tratan en el contexto de la Página Web se almacenarán en Chile y en otros países.',
      },
      {
        id: 93,
        content: 'Algunos de los destinatarios de los datos con los que Bancame comparte sus datos personales pueden estar situados en países distintos del país en el que se recogieron originalmente sus datos personales. Es posible que las leyes de esos países no ofrezcan el mismo nivel de protección de datos que el país en el que proporcionó inicialmente sus datos. No obstante, cuando transferimos sus datos personales a destinatarios en otros países, protegeremos esos datos personales tal y como se describe en esta política y de conformidad con la legislación aplicable.',
      },
      {
        id: 94,
        content: 'Adoptamos una variedad de medidas para garantizar que sus datos personales al ser transferidos a receptores fuera de Chile, los datos reciban la protección adecuada, de acuerdo con las normas de protección de datos. Utilizamos una variedad de medidas de organización y técnicas para garantizar que sus datos personales transferidos a estos países, reciban la protección adecuada de acuerdo con las normas de protección de datos; esto incluye la celebración de contratos apropiados para garantizar un adecuado estándar de protección, requiriendo al receptor una medidas adecuadas de organización y técnicas, como también verificar que el receptor ha adoptado mecanismos de transferencia de datos adecuados, en cumplimiento con la legislación aplicable.',
      },
      {
        id: 95,
        content: '<b>Conservación de sus datos</b> Sus datos personales pasarán a ser datos no identificables que se almacenan, a menos que y hasta que usted solicite eliminar sus datos personales, o bien, que por disposiciones legales o regulatorias deban ser mantenidos por períodos de tiempo que van más allá de este término, en cuyo caso se mantendrán por el periodo que indique dicha normativa.',
      },
    ],
  },
  {
    id: 10,
    title: 'Sus derechos relacionados con sus datos personales.',
    content: [
      {
        id: 101,
        content: 'Usted tiene derecho a solicitar: (i) Confirmación en relación si Bancame ha procesado datos personales sobre usted; (ii) El acceso a sus datos personales, como, por ejemplo, su origen, sus receptores (cuando corresponda) y finalidad del almacenamiento; (iii) Una copia en formato electrónico de sus datos personales (portabilidad); y (iv) La corrección de sus datos personales si están incompletos o son inexactos; y la eliminación, restricción o bloqueo de sus datos personales en ciertos casos contemplados por las leyes.',
      },
      {
        id: 102,
        content: 'Estos derechos no son absolutos. Cuando hayamos obtenido su consentimiento para el tratamiento de sus datos personales, usted tiene derecho a revocar su consentimiento en cualquier momento. Esto no afectará la legalidad del procesamiento que tuvo lugar previo al retiro del consentimiento.',
      },
      {
        id: 103,
        content: 'Si desea, podrá solicitar una copia de sus datos personales o ejercer alguno de sus otros derechos, por favor contactar con nosotros enviando un correo electrónico a info@banca.me. Por favor tenga en cuenta que deberemos validar su identidad previa a entregar la información mencionada precedentemente.',
      },
    ],
  },
  {
    id: 11,
    title: 'Cambios a la Política.',
    content: [
      {
        id: 111,
        content: 'La ley aplicable y nuestras prácticas cambian con el tiempo. Si decidimos actualizar nuestra Política, publicaremos los cambios en nuestra Página Web. Si cambiamos significativamente la forma en que tratamos los datos personales, le proporcionaremos un aviso previo, y solicitaremos su consentimiento antes de llevar a cabo dichos cambios. Si usted no aceptare las modificaciones y/o actualizaciones a la Política de Privacidad cuando correspondiere, Bancame podrá dar término a su cuenta registrada en la Página Web, respetando los plazos de pago de las condiciones originales pactadas.',
      },
      {
        id: 112,
        content: 'Si las modificaciones y/o actualizaciones, a la Política, no fueren significativas, les proporcionaremos de un aviso previo.',
      },
      {
        id: 113,
        content: 'Le recomendamos encarecidamente leer nuestra Política y mantenerse informado de nuestras prácticas.',
      },
    ],
  },
  {
    id: 12,
    title: 'Preguntas y comentarios.',
    content: [
      {
        id: 121,
        content: 'Preguntas, comentarios e inquietudes sobre nuestra política y prácticas de privacidad son bienvenidas. Si desea enviarnos sus comentarios, preguntas o inquietudes, o si desea ejercer sus derechos relacionados con sus datos personales, por favor contactarnos enviando un correo electrónico a info@banca.me.',
      },
      {
        id: 122,
        content: 'La información de contacto de Bancame es: Bancame SpA domiciliado en Avenida Nueva Providencia 1881, oficina 1201, Providencia. Con correo electrónico en info@banca.me.',
      },
      {
        id: 123,
        content: 'Si usted nos contacta con una queja de privacidad será evaluada con el objetivo de resolver el asunto de forma pertinente y efectiva. Usted también tiene el derecho de interponer una queja con la entidad supervisora pertinente en el país que usted resida.',
      },
    ],
  },
];

type Partner = {
  name: string,
  origin: string,
  legalName: string,
  address: string,
  email: string,
}

export const termsAndConditionsPartners = (partner: string): TermsAndConditionsType => {
  const partnersText: { [id: string] : Partner; } = {
    bricsa: {
      name: 'Bricsa',
      origin: 'bricsa',
      legalName: 'Bricsa Inmobiliaria',
      address: 'Nueva Lyon 145, Piso 9, Providencia, Santiago',
      email: 'contacto@bricsa.cl',
    },
    ideal: {
      name: 'I-Deal',
      origin: 'ideal',
      legalName: 'I-Deal',
      address: 'Alonso de Cordoba 4125, Santiago',
      email: 'contacto@i-deal.cl',
    },
    siena: {
      name: 'Inmobiliaria Siena',
      origin: 'siena',
      legalName: 'Inmobiliaria Siena',
      address: 'Los Militares 6191, Piso 12',
      email: 'contacto@siena.cl',
    },
    libercoop: {
      name: 'Libercoop',
      origin: 'libercoop',
      legalName: 'Cooperativa de Ahorro y Crédito Libercoop',
      address: 'Alfredo Barros Errázuriz 1900 Of 202, Providencia, Santiago',
      email: 'contacto@libercoop.cl',
    },
    portalInmobiliario: {
      name: 'Portal Inmobiliario',
      origin: 'portalInmobiliario',
      legalName: 'Mercado Libre Chile Ltda',
      address: 'Avenida Apoquindo 4800, Torre 2, Piso 21, Las Condes, Santiago, Chile',
      email: 'info@portalinmobiliario.com',
    },
    vimac: {
      name: 'Vimac',
      origin: 'Vimac',
      legalName: 'Inmobiliaria Vimac',
      address: 'Av Bosques de Montemar 65, piso 13, Viña del Mar',
      email: 'idiaz@vimac.cl',
    },
    fundamenta: {
      name: 'Fundamenta',
      origin: 'fundamenta',
      legalName: 'Inmobiliaira Fundamenta',
      address: 'Av Presidente Riesco 5561, Piso 6, Santiago',
      email: 'servicioalcliente@fundamenta.cl',
    },
    colegual: {
      name: 'Inmobiliria Colegual',
      origin: 'colegual',
      legalName: 'Inmobiliaira Colegual',
      address: 'Baquedano 815, Llanquihue, Los Lagos',
      email: 'info@parquetresvolcanes.com',
    },
    numancia: {
      name: 'Numancia Inmobiliaria',
      origin: 'numancia',
      legalName: 'Inmobiliaira Numancia',
      address: 'Av Libertad 269, Piso 13, Viña del Mar',
      email: 'ventas@numancia.cl',
    },
    crillon: {
      name: 'Inmobiliaria Crillon',
      origin: 'crillon',
      legalName: 'Inmobiliaira Crillon',
      address: 'Av Quilin 7100, Peñalolen, Santiago',
      email: 'contacto@crillon.cl',
    },
    altura: {
      name: 'Inmobiliaria Altura',
      origin: 'altura',
      legalName: 'Inmobiliaira Altura',
      address: 'Alonso de Cordoba 4355 Of 702, Vitacura, Santiago',
      email: 'contacto@ialtura.cl',
    },
    andes: {
      name: 'Inmobiliaria Andes',
      origin: 'andes',
      legalName: 'Inmobiliaira Andes',
      address: 'Av del Condor 600 Piso 6, Huechuraba, Santiago',
      email: 'contacto@iandes.cl',
    },
    metra: {
      name: 'Inmobiliaria Metra',
      origin: 'metra',
      legalName: 'Inmobiliaira Metra',
      address: 'Candelaria Goyonechea 3868 OF 42, Vitacura, Santiago',
      email: 'ventas@metra.cl',
    },
    puelo: {
      name: 'Puelo Gestión',
      origin: 'puelo',
      legalName: 'Puelo Gestión',
      address: 'Tajamar 481 Of 1101, Las Condes, Santiago',
      email: 'barriotorcazas@puelogestion.cl',
    },
    besalco: {
      name: 'Besalco',
      origin: 'besalco',
      legalName: 'Besalco S.A.',
      address: 'Ebro 2705, Las Condes, Santiago',
      email: 'auditoria@besalco.cl',
    },
    gpr: {
      name: 'Constructora & Inmobiliaria GPR',
      origin: 'gpr',
      legalName: 'Constructora & Inmobiliaria GPR',
      address: 'Andres Bello 1877, Providencia, Santiago',
      email: 'contacto@gpr.cl',
    },
    euro: {
      name: 'Euro Inmobiliaria',
      origin: 'euro',
      legalName: 'EuroCorp Dos S.A.',
      address: 'Flor de Azucena 111 Piso 8, Las Condes, Santiago',
      email: 'marketin@eurocorp.cl',
    },
    urmeneta: {
      name: 'Urmeneta',
      origin: 'urmeneta',
      legalName: 'Urmeneta Gestión Inmobiliaria',
      address: 'Eliodoro Yañez 2915, Providencia, Santiago',
      email: 'info@urmenetagi.cl',
    },
    fai: {
      name: 'Inmobiliaria Fai',
      origin: 'fai',
      legalName: 'Inmobiliaria Fai',
      address: 'Av Apoquindo 6550, Las Condes, Santiago',
      email: 'info@flesan.cl',
    },
    nash: {
      name: 'Nash',
      origin: 'nash',
      legalName: 'Nash Abogados',
      address: 'San Sebastian 2807 Oficina 213, Santiago',
      email: 'estudio@nash.cl',
    },
    goplaceit: {
      name: 'Go Place It',
      origin: 'goplaceit',
      legalName: 'goplaceit Spa',
      address: 'Av Nueva Providencia 1881 Of 1201, Santiago',
      email: 'hello@goplaceit.com',
    },
    buenaventura: {
      name: 'Inmobiliaria Buenaventura',
      origin: 'buenaventura',
      legalName: 'Inmobiliaria Buenaventura',
      address: 'Hendaya 263, Las Condes, Santiago',
      email: 'contacto@ibuenaventura.cl/',
    },
    icafal: {
      name: 'Icafal',
      origin: 'icafal',
      legalName: 'Icafal SA',
      address: 'Augusto Leguía Sur N°160, Of. 51, Las Condes',
      email: 'contacto@icafal.cl',
    },
    coopeuch: {
      name: 'Coopeuch',
      origin: 'coopeuch',
      legalName: 'Cooperativa de Ahorro y Crédito Coopeuch Ltda',
      address: 'Agustinas 1141, Santiago',
      email: 'contacto@coopeuch.cl',
    },
    tricapitals: {
      name: 'Tricapitals',
      origin: 'tricapitals',
      legalName: 'Tricapitals',
      address: 'Apoquindo 3200, Piso 10, Las Condes, Santiago',
      email: 'contacto@tricapitals.com',
    },
    buyDepa: {
      name: 'Buydepa',
      origin: 'buyDepa',
      legalName: 'Buydepa',
      address: 'Rosario Norte 615 Oficina 1303, Las Condes, Santiago',
      email: 'contacto@buydepa.com',
    },
    conquista: {
      name: 'Conquista',
      origin: 'conquista',
      legalName: 'Inversiones Conquista SpA',
      address: 'Carlos Vial Infante Sitio 10, Pirque',
      email: 'contacto@iconquista.cl',
    },
    ecasa: {
      name: 'Ecasa',
      origin: 'ecasa',
      legalName: 'Ecasa Inmobiliaria',
      address: 'Av Vitacura 2969, Piso 13, Vitacura, Santiago',
      email: 'centralizador@ecasa.cl',
    },
    rentaCapital: {
      name: 'Renta Capital',
      origin: 'rentaCapital',
      legalName: 'Renta Capital',
      address: 'Av Nueva Costanera 3605 Of 701, Vitacura, Santiago',
      email: 'contacto@renta-capital.cl',
    },
    brick: {
      name: 'Inmobiliaria Brick',
      origin: 'brick',
      legalName: 'Inmobiliaria Biangular SpA',
      address: 'Americo Vespucio Norte 1090 Of. 403, Vitacura, Santiago',
      email: 'contacto@ibrick.cl',
    },
    manquehue: {
      name: 'Inmobiliaria Manquehue',
      origin: 'manquehue',
      legalName: 'Inmobiliaria Manquehue S.A.',
      address: 'Av Santa Maria 6350, Piso 4, Vitacura',
      email: 'contacto@imanquehue.com',
    },
    inspira: {
      name: 'Inmobiliaria Inspira',
      origin: 'inspira',
      legalName: 'Inmobiliaria Inspira Spa',
      address: 'Av La Dehesa 1201, Of 811, Lo Barnechea, Santiago',
      email: 'contacto@inspira.cl',
    },
    norteVerde: {
      name: 'Norte Verde',
      origin: 'norteVerde',
      legalName: 'Norte Verde Spa.',
      address: 'Av. Los Dominicos 8630 of 806, Las Condes, Santiago',
      email: 'contacto@norte-verde.cl',
    },
    gespania: {
      name: 'Inmobiliaria Gespania',
      origin: 'gespania',
      legalName: 'Inmobiliaria Gespania SA.',
      address: 'Av. Apoquindo 7935 Piso 6 Torre A, Las Condes, Santiago',
      email: 'contacto@gespania.cl',
    },
    paz: {
      name: 'Inmobiliaria Paz',
      origin: 'paz',
      legalName: 'Paz Corp',
      address: 'Av. Apoquindo 4501 of 2104, Las Condes, Santiago',
      email: 'ventas@paz.cl',
    },
    indesa: {
      name: 'Indesa',
      origin: 'indesa',
      legalName: 'Indesa Servicios Comerciales SpA.',
      address: 'Augusto Leguía Norte 100, OF 911. Las Condes',
      email: 'contacto@indesa.cl',
    },
    tenpo: {
      name: 'Tenpo',
      origin: 'tenpo',
      legalName: 'Tenpo Payments S.A.',
      address: 'Avenida Apoquindo 3721, Oficina 91, Las Condes, Santiago',
      email: 'soportecc@tenpo.cl',
    },
  };
  return [
    {
      id: 2,
      title: 'Introducción',
      content: [
        {
          id: 21,
          content: `Esta política de privacidad describe los datos personales recopilados o generados (tratados) cuando usted interactúa con la página web de propiedad de la sociedad Bancame SpA (creditos.banca.me/preloan?origin=${partnersText[partner].origin}) - (la “Página Web”). Asimismo, esta política describe los tipos de datos personales recopilados y cómo se utilizan, comparten y protegen. También explica las opciones que tiene en relación con sus datos personales y cómo puede ponerse en contacto con nosotros.`,
        },
        {
          id: 22,
          content: 'En este sentido, se entenderá que al ingresar tu cédula de identidad o rol único tributario y presionar la casilla “continuar” de la Página Web, usted reconoce y declara haber leído, comprendido y aceptado íntegramente el contenido del presente instrumento en todas sus partes.',
        },
      ],
    },
    {
      id: 3,
      title: '¿Quién es responsable del procesamiento de sus datos personales?',
      content: partnersText[partner].origin === 'goplaceit'
        ? [
          {
            id: 31,
            content: 'Cuando se interactúe con la Página Web o se ingresen tus datos para efectos de recibir los Servicios de Bancame así como los de la empresa “Goplaceit SpA”, (Servicios de GoPlaceit), con el objeto de postular a una evaluación de tus antecedentes financieros, para una simulación de un crédito de consumo para financiar el todo o parte del pie (crédito pie) del inmueble que estás evaluando adquirir y/o la evaluación de solvencia económica para la obtención de un crédito hipotecario, tus datos personales serán procesados por Bancame SpA (“Bancame”), para ser luego compartidos con la empresa “Goplaceit Spa” (Go Place It y en conjunto con Bancame, como las “Partes”). Lo anterior surge como una necesidad de cumplir conforme lo requiere el "Reglamento sobre Análisis de Solvencia Económica e Información a los Consumidores”, que se enmarca en dentro de la “Ley Pro Consumidor”, que estableció una serie de medidas para incentivar la protección de los derechos de los consumidores. Bajo este nuevo marco, cualquier entidad que ofrezca créditos deberá realizar un análisis detallado de la capacidad de pago de los solicitantes antes de aprobar cualquier operación de crédito, y deberá informar el resultado de dicho análisis, con la aceptación o rechazo de la operación de crédito de dinero. Para lo anterior solo nos basaremos en datos concretos y actuales, obtenidos de fuentes oficiales, para tomar decisiones informadas.',
          },
          {
            id: 32,
            content: 'Como parte de los Servicios de GoPlaceIt, se considera necesario el recibir tu información personal, la evaluación de los antecedentes financieros, el resultado de la simulación de precalificación del crédito pie y/o de un crédito hipotecario, para poder transmitir esta información a las instituciones financieras aliadas de GoPlaceIt, así como entregar el resultado del informe a la Inmobiliaria vendedora del inmueble que estás evaluando adquirir, como también a los intermediarios o brokers, todos los anteriores aliados de GoPlaceIt. El resultado del informe podrá ser entregado a los anteriores de manera agregada, indicando el monto máximo que pudiere otorgarse según la precalificación, y con los datos necesarios para identificar a quien se haya sometido a la referida precalificación. Esta misma información le será enviada a usted, dando cumplimiento así al citado Reglamento. ',
          },
          {
            id: 33,
            content: 'La mera visita de la Página Web o el hecho de aceptar estos Términos y Condiciones no impone ningún tipo de obligación por parte de GoPlaceIt. La contratación de un crédito pie y/o de un crédito hipotecario, incluso obteniendo una precalificación favorable a través nuestra, dependerá finalmente exclusivamente de las instituciones financieras que lo otorgan, y por tanto, GoPlaceIt se considera un tercero para todos los efectos. Te recomendamos leer con detención los documentos contractuales asociados a cada crédito, producto y/o servicio antes de proceder a su contratación.',
          },
          {
            id: 34,
            content: 'Te informamos que Go PlaceIt tiene un convenio con Bancame para operar la Página Web, sin perjuicio de que la propiedad de la Página Web corresponde a Bancame. Cuando se registre una cuenta y/o ingrese con su cuenta en la Página Web, Bancame procederá a procesar tus datos personales, acorde a la presente Política y a la legislación y regulación aplicable.',
          },
          {
            id: 35,
            content: 'Esta Política describe la manera en que Bancame procesará tus datos y como las Partes harán uso y protegerán tus datos personales.',
          },
          {
            id: 36,
            content: 'Bancame será responsable por el procesamiento de sus datos personales y  será responsable de la protección de los datos personales y el uso que le otorguen. Por su parte, Go Place It solo será responsable final del procesamiento de datos que realice según la descripción de los Servicios de Go Place It.',
          },
        ]
        : [
          {
            id: 31,
            content: `Cuando se interactúe con la Página Web o se ingresen tus datos para efectos de recibir los servicios de la empresa “${partnersText[partner].legalName}”, con el objeto de postular a una evaluación de tus antecedentes financieros, para una simulación de un crédito hipotecario, tus datos personales serán procesados por Bancame SpA (“Bancame”), para ser luego compartidos con la empresa “${partnersText[partner].legalName}” (${partnersText[partner].name} y en conjunto con Bancame, como las “Partes”).`,
          },
          {
            id: 32,
            content: `${partnersText[partner].name} está licenciada para operar la Página Web, sin perjuicio de que la propiedad de la Página Web corresponde a Bancame. Cuando se registre una cuenta y/o ingrese con su cuenta en la Página Web, Bancame procederá a procesar tus datos personales, acorde a la presente Política y a la legislación y regulación aplicable.`,
          },
          {
            id: 33,
            content: 'Esta Política describe la manera en que Bancame procesará tus datos y como las Partes harán uso y protegerán tus datos personales.',
          },
          {
            id: 34,
            content: partnersText[partner].origin === 'goplaceit' ? 'Bancame será responsable por el procesamiento de sus datos personales y  será responsable de la protección de los datos personales y el uso que le otorguen. Por su parte, Go Place It solo será responsable final del procesamiento de datos que realice según la descripción de los Servicios de Go Place It.' : 'Bancame será responsable por el procesamiento de sus datos personales. Las Partes serán responsables de la protección de los datos personales y el uso que le otorguen.',
          },
        ],
    },
    {
      id: 3,
      title: '¿Qué datos personales recolectamos y cuándo?',
      content: [
        {
          id: 31,
          content: partnersText[partner].origin === 'goplaceit' ? 'Para proporcionarle los servicios que solicite, le pediremos ciertos datos personales. Por ejemplo, cuando cree una cuenta o utilice la Página Web, para ser sujeto de evaluación para optar a un crédito pie y/o crédito hipotecario. Conforme al citado Reglamento y a la ley vigente, el proceso de revisión de solvencia económica deberá evaluar, a lo menos: 1. Los ingresos presentes del consumidor y aquellos previsibles durante el plazo de vigencia del contrato de operación de crédito; 2.El nivel de endeudamiento; 3. La morosidad actual.' : 'Para proporcionarle los servicios que solicite, le pediremos ciertos datos personales. Por ejemplo, cuando cree una cuenta o utilice la Página Web, para ser sujeto de evaluación para optar a un crédito hipotecario.',
        },
        {
          id: 32,
          content: 'Adicionalmente, cuando proporcione datos personales, deberá confirmar que la información entregada es verdadera, precisa y actualizada. De igual manera, usted consentirá en informar a Bancame, de cualquier corrección, actualización o ajuste, que nosotros debemos implementar a los datos personales proporcionados, contactándonos al siguiente correo electrónico info@banca.me.',
        },
        {
          id: 33,
          content: 'Los datos personales que les solicitaremos son los siguientes: /i/ su rol único tributario, para efectos de recopilar información bancaria y financiera en instituciones como Transunion, Proriesgo, Sinacofi, Comisión para el Mercado Financiero, Sencillito y Servipag; y, /ii/ su clave única del Servicio del Registro Civil e Identificación y los datos allí contenidos, tales como certificado de cotizaciones, última renta imponible, empleador, tipo de contrato, antigüedad laboral, créditos con cajas de compensación, fecha de nacimiento, nombre, sexo, hijos, defunción, mail, teléfono, informe de deudas, monto total deudas activas por banco e instituciones financieras, y monto total deudas morosas por banco e instituciones financieras, préstamo solidario y domicilio.',
        },
        {
          id: 34,
          content: 'Al interactuar con la Página Web, algunos datos se recopilan automáticamente desde su dispositivo o navegador web.',
        },
      ],
    },
    {
      id: 4,
      title: 'Menores de edad.',
      content: [
        {
          id: 41,
          content: 'Cumplimos con las leyes locales y no permitimos que los menores de edad se registren en la Página Web, cuando no alcancen la edad legal mínima requerida en el país en el que residen. ',
        },
      ],
    },
    {
      id: 5,
      title: 'Herramientas para administrar los datos personales que recolectamos.',
      content: [
        {
          id: 51,
          content: partnersText[partner].origin === 'goplaceit' ? 'Al utilizar la Página Web, también le informamos sobre nuestra recopilación u obtenemos consentimiento para ciertas prácticas. Se obtendrá su consentimiento a través de la Página Web conforme lo requiere las modificaciones introducidas al artículo 17 N de la ley Nº 19.496, que establece normas sobre protección de los derechos de los consumidores en las materias que esta política aborda' : 'Al utilizar la Página Web, también le informamos sobre nuestra recopilación u obtenemos consentimiento para ciertas prácticas. Se obtendrá su consentimiento a través de la Página Web.',
        },
      ],
    },
    {
      id: 6,
      title: '¿Por qué y cómo utilizamos (tratamos) sus datos personales?',
      content: [
        {
          id: 61,
          content: partnersText[partner].origin === 'goplaceit' ? 'i. Para proporcionar las funciones de la plataforma y servicios que solicita. Cuando utilice la Página Web, utilizaremos sus datos para proporcionarle un servicio de evaluación de sus antecedentes financieros para una simulación de un crédito pie y/o crédito hipotecario conforme lo requiere el "Reglamento sobre Análisis de Solvencia Económica e Información a los Consumidores”, que se enmarca en dentro de la “Ley Pro Consumidor”, que estableció una serie de medidas para incentivar la protección de los derechos de los consumidores cuando personas naturales requiere financiamiento con los bancos e instituciones financieras que tuvieren convenio con Go Place It. Go Place It utilizará sus datos personales según sea necesario para brindarles los Servicios de Go Place It.' : `<b>i. Para proporcionar las funciones de la plataforma y servicios que solicita.</b>Cuando utilice la Página Web, utilizaremos sus datos para proporcionarle un servicio de evaluación de sus antecedentes financieros para una simulación de un crédito hipotecario, con los bancos e instituciones financieras con que tuvieren convenio con ${partnersText[partner].name}.`,
        },
        {
          id: 62,
          content: `<b>ii. Para comunicar información sobre productos, servicios, y para otros objetivos promocionales, de parte de las Partes.</b> Cuando brinde su consentimiento en la Página Web, Bancame y/o ${partnersText[partner].name} podrán enviar comunicaciones publicitarias y noticias sobre sus productos, servicios, y otras promociones. Puede darse de baja en cualquier momento después de haber dado su consentimiento y toda comunicación comercial describirá las formas de contacto, para solicitar la suspensión de estos mensajes.`,
        },
        {
          id: 63,
          content: 'Si usted es un cliente actual de Bancame (por ejemplo, si ya ha contratado los servicios en la Página Web), también podremos utilizar la dirección de correo electrónico proporcionada para enviarle comunicaciones de marketing sobre productos o servicios similares de Bancame, cuando esté permitido por la ley aplicable (salvo que haya renunciado a ello). En otros casos, le solicitaremos su consentimiento para enviarle información de marketing. ',
        },
        {
          id: 64,
          content: '<b>iv. Para proteger la propiedad, seguridad o derechos propios o de terceros.</b> También es posible que Bancame utilice sus datos personales, acerca de cómo usted utiliza la Página Web, para prevenir o detectar abusos, usos ilegales e infracciones de nuestros Términos de Uso y para cumplir con órdenes judiciales, solicitudes gubernamentales o leyes aplicables.',
        },
        {
          id: 65,
          content: '<b>v. Para objetivos generales de investigación y análisis</b> Las Partes utilizarán los datos acerca de las personas que ingresen a la Página Web y servicios para comprender el comportamiento o las preferencias de los clientes.',
        },
        {
          id: 66,
          content: '<b>vi. Otros usos.</b> También es posible que se utilice su información personal en otras maneras y en ese caso, lo notificaremos específicamente al momento de recopilar la información y obtener el consentimiento que fuere necesario.',
        },
        {
          id: 67,
          content: '<b>vii. Fundamentos Legales</b> Como se señaló anteriormente, Bancame será aquella quien procesará sus datos personales. Ahora bien, para procesar sus datos personales, nos basamos en ciertos fundamentos legales, según cómo interactúe con la Página Web. Por lo anterior, nuestra base legal será el consentimiento, como es requerido ante la legislación local.',
        },
        {
          id: 68,
          content: 'También nos basamos en otros fundamentos legales, como nuestros intereses legítimos como empresa, para cumplir con una obligación legal.',
        },
      ],
    },
    {
      id: 7,
      title: 'Transmisión de sus datos personales.',
      content: [
        {
          id: 71,
          content: 'Bancame comparte sus datos personales con:',
        },
        {
          id: 72,
          content: partnersText[partner].origin === 'goplaceit' ? 'i. Go Place It, para las finalidades y bajo las condiciones descritas anteriormente, la que a su vez podrá compartirlas según sea necesario para la prestación de los Servicios de Go Place It, quien  su vez estará autorizada a compartir dichos datos en los casos siguientes.' : `i. <b>${partnersText[partner].name}</b>, para las finalidades y bajo las condiciones descritas anteriormente.`,
        },
        {
          id: 73,
          content: `ii. Otros terceros en la medida que fuere necesaria para: (i) cumplir con una solicitud del gobierno, una orden judicial o ley aplicable; (ii) prevenir usos ilegales de la Página Web o violaciones de los Términos de Uso y las políticas de la Página Web; (iii) defender a Bancame y/o ${partnersText[partner].name} contra reclamaciones de terceros; y (iv) ayudar en la prevención o investigación de fraude (por ejemplo, falsificación). `,
        },
        {
          id: 74,
          content: 'A cualquier otro tercero cuando se lo hayamos comunicado y usted haya prestado consentimiento.',
        },
        {
          id: 75,
          content: 'También podemos transferir los datos personales que Bancame tenga sobre usted, en caso de que vendamos o transfiramos la totalidad o una parte de nuestro negocio o activos (incluyendo en caso de reorganización, escisión, disolución o liquidación).',
        },
      ],
    },
    {
      id: 8,
      title: 'Protección y administración de sus datos personales.',
      content: [
        {
          id: 81,
          content: '<b>Encriptación y Seguridad</b> En el procesamiento de datos, Bancame cuenta con una variedad de medidas de seguridad técnica y organizativa, incluyendo herramientas de encriptación y autenticación, para mantener la seguridad de sus datos personales.',
        },
        {
          id: 82,
          content: '<b>Transferencias internacionales de datos</b> Los datos personales que se recopilan o se tratan en el contexto de la Página Web se almacenarán en Chile y en otros países.',
        },
        {
          id: 83,
          content: 'Algunos de los destinatarios de los datos con los que Bancame comparte sus datos personales pueden estar situados en países distintos del país en el que se recogieron originalmente sus datos personales. Es posible que las leyes de esos países no ofrezcan el mismo nivel de protección de datos que el país en el que proporcionó inicialmente sus datos. No obstante, cuando transferimos sus datos personales a destinatarios en otros países, protegeremos esos datos personales tal y como se describe en esta política y de conformidad con la legislación aplicable.',
        },
        {
          id: 84,
          content: 'Adoptamos una variedad de medidas para garantizar que sus datos personales al ser transferidos a receptores fuera de Chile, los datos reciban la protección adecuada, de acuerdo con las normas de protección de datos. Utilizamos una variedad de medidas de organización y técnicas para garantizar que sus datos personales transferidos a estos países, reciban la protección adecuada de acuerdo con las normas de protección de datos; esto incluye la celebración de contratos apropiados para garantizar un adecuado estándar de protección, requiriendo al receptor una medidas adecuadas de organización y técnicas, como también verificar que el receptor ha adoptado mecanismos de transferencia de datos adecuados, en cumplimiento con la legislación aplicable.',
        },
        {
          id: 85,
          content: partnersText[partner].origin === 'goplaceit' ? 'Si desea, podrá solicitar una copia de sus datos personales o ejercer alguno de sus otros derechos, por favor contactar con nosotros enviando un correo electrónico a contacto@pistos.cl. Por favor tenga en cuenta que deberemos validar su identidad previa a entregar la información mencionada precedentemente.' : '<b>Conservación de sus datos</b> Sus datos personales pasarán a ser datos no identificables que se almacenan, a menos que y hasta que usted solicite eliminar sus datos personales, o bien, que por disposiciones legales o regulatorias deban ser mantenidos por períodos de tiempo que van más allá de este término, en cuyo caso se mantendrán por el periodo que indique dicha normativa.',
        },
      ],
    },
    {
      id: 9,
      title: 'Sus derechos relacionados con sus datos personales.',
      content: [
        {
          id: 91,
          content: 'Usted tiene derecho a solicitar: (i) Confirmación en relación si Bancame ha procesado datos personales sobre usted; (ii) El acceso a sus datos personales, como, por ejemplo, su origen, sus receptores (cuando corresponda) y finalidad del almacenamiento; (iii) Una copia en formato electrónico de sus datos personales (portabilidad); y (iv) La corrección de sus datos personales si están incompletos o son inexactos; y la eliminación, restricción o bloqueo de sus datos personales en ciertos casos contemplados por las leyes.',
        },
        {
          id: 92,
          content: 'Estos derechos no son absolutos. Cuando hayamos obtenido su consentimiento para el tratamiento de sus datos personales, usted tiene derecho a revocar su consentimiento en cualquier momento. Esto no afectará la legalidad del procesamiento que tuvo lugar previo al retiro del consentimiento.',
        },
        {
          id: 93,
          content: 'Si desea, podrá solicitar una copia de sus datos personales o ejercer alguno de sus otros derechos, por favor contactar con nosotros enviando un correo electrónico a info@banca.me. Por favor tenga en cuenta que deberemos validar su identidad previa a entregar la información mencionada precedentemente.',
        },
      ],
    },
    {
      id: 10,
      title: 'Cambios a la Política.',
      content: [
        {
          id: 101,
          content: 'La ley aplicable y nuestras prácticas cambian con el tiempo. Si decidimos actualizar nuestra Política, publicaremos los cambios en nuestra Página Web. Si cambiamos significativamente la forma en que tratamos los datos personales, le proporcionaremos un aviso previo, y solicitaremos su consentimiento antes de llevar a cabo dichos cambios. Si usted no aceptare las modificaciones y/o actualizaciones a la Política de Privacidad cuando correspondiere, Bancame podrá dar término a su cuenta registrada en la Página Web, respetando los plazos de pago de las condiciones originales pactadas.',
        },
        {
          id: 102,
          content: 'Si las modificaciones y/o actualizaciones, a la Política, no fueren significativas, les proporcionaremos de un aviso previo.',
        },
        {
          id: 103,
          content: 'Le recomendamos encarecidamente leer nuestra Política y mantenerse informado de nuestras prácticas. ',
        },
      ],
    },
    {
      id: 11,
      title: 'Preguntas y comentarios.',
      content: [
        {
          id: 111,
          content: `Preguntas, comentarios e inquietudes sobre nuestra política y prácticas de privacidad son bienvenidas. Si desea enviarnos sus comentarios, preguntas o inquietudes, o si desea ejercer sus derechos relacionados con sus datos personales, por favor contactarnos enviando un correo electrónico a ${partnersText[partner].origin === 'goplaceit' ? 'contacto@pistos.cl' : 'info@banca.me.'}`,
        },
        {
          id: 112,
          content: 'La información de contacto de Bancame es: Bancame SpA domiciliado en Avenida Nueva Providencia 1881, oficina 1201, Providencia. Con correo electrónico en info@banca.me.',
        },
        {
          id: 113,
          content: `La información de contacto de ${partnersText[partner].name} es: ${partnersText[partner].legalName} domiciliado en ${partnersText[partner].address}. Con correo electrónico en ${partnersText[partner].email}.`,
        },
        {
          id: 114,
          content: 'Si usted nos contacta con una queja de privacidad será evaluada con el objetivo de resolver el asunto de forma pertinente y efectiva. Usted también tiene el derecho de interponer una queja con la entidad supervisora pertinente en el país que usted resida.',
        },
      ],
    },
  ];
};
