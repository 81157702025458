import * as React from 'react';
import {
  Button,
  Card,
  Grid, Typography,
} from '@mui/material';
import ViewTitle from '../../../components/ViewTitle';
import useStyles, { sx } from './styles';
import type { ProductsListProps } from './types';
import BoughtProducts from './boughtProducts';
import ActionLinks from './actionLinks';

export default function ProductsList(props: ProductsListProps) {
  const { userReportsData, token } = props;
  const classes = useStyles();

  const goToSurvey = () => {
    window.open('https://docs.google.com/forms/d/1lgkyo3HRKgaE2bBcgwefBzfdZeD81lmsJZtEO_0uKyE');
  };

  return (
    <>
      <ViewTitle
        title="Guia.Me"
        color="secondary"
        variant="h4"
        subtitle="En esta sección verás el resumen de tus compras en Banca.Me"
      />
      <Grid
        container
        className={classes.gridContainer}
        rowGap={10}
      >
        <Grid
          item
          xs={12}
          md={6}
          className={classes.gridItem}
        >
          <BoughtProducts
            userReportsData={userReportsData}
            token={token}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.gridItem}
        >
          <ActionLinks
            userReportsData={userReportsData}
            token={token}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.gridItem}
        >
          <Card sx={sx().cardLinkContainer}>
            <div>
              <Typography
                variant="h6"
                gutterBottom
                style={{ fontWeight: 1000, marginBottom: '1em' }}
              >
                ¿Tienes un minuto?
              </Typography>
              {' '}
              <Button variant="contained" style={{ maxWidth: '240px' }} onClick={goToSurvey}>Ayúdanos a mejorar</Button>
            </div>
          </Card>
        </Grid>
      </Grid>
      <div style={{ height: 50 }} />
    </>
  );
}
