import React from 'react';
import {
  FormControl, MenuItem, Select, Typography, useTheme,
} from '@mui/material';
import AlertDialog from '../../../components/AlertDialog';
import { archiveUnemployedPLR } from '../v3/PreLoanForm/formLogic';
import { FormSchemaV3 } from '../../../types/preloan/v3';

export type WorkerType = 'DEPENDENT_PUBLIC'|'DEPENDENT_PRIVATE'|'INDEPENDENT'
type DependentStatusDialogProps = {
    action: (workerType: WorkerType) => Promise<void>,
    isTriggered: boolean,
    formSchema: FormSchemaV3,
    handleCurrentData: (data: FormSchemaV3) => void,
    handleStep: (step: number) => void,
}

export default function DependentStatusDialog(props: DependentStatusDialogProps) {
  const {
    action,
    isTriggered,
    formSchema,
    handleCurrentData,
    handleStep,
  } = props;
  const [workerType, setWorkerType] = React.useState<WorkerType|'UNEMPLOYED'|''>('');
  const [triggerClose, setTriggerClose] = React.useState(false);
  const [error, setError] = React.useState(0);
  const theme = useTheme();
  const handleAction = async () => {
    setError(0);
    if (workerType === 'UNEMPLOYED') {
      return archiveUnemployedPLR(formSchema, handleCurrentData, handleStep);
    }
    try {
      return action(workerType as WorkerType);
    } catch (e) {
      return setError(e as number);
    }
  };
  const handleTriggerClose = () => {
    setTriggerClose(true);
    setError(0);
    setWorkerType('');
  };
  React.useEffect(() => {
    setTimeout(() => setTriggerClose(false), 3000);
  }, [triggerClose]);
  return (
    <AlertDialog
      triggerClose={triggerClose}
      dialogTitle={<b>Información adicional</b>}
      dialogContent={(
        <div>
          <Typography gutterBottom>
            Por favor ayúdanos verificando tu situación laboral
            {' '}
            <span style={{ color: theme.customPalette.tertiary.main }}>
              para continuar con tu evaluación.

            </span>
          </Typography>
          <Typography fontWeight="bold" gutterBottom>
            ¿Podrías indicarnos tu situación laboral?
          </Typography>
          <FormControl
            fullWidth
          >
            <Select
              value={workerType}
              variant="outlined"
              onChange={(e) => setWorkerType(e.target.value as WorkerType|'')}
              displayEmpty
              renderValue={
                workerType !== '' ? undefined : () => <Typography color="text.secondary">Seleccionar...</Typography>
                        }
            >
              <MenuItem value="INDEPENDENT" style={{ whiteSpace: 'normal' }}>
                <span>
                  Soy trabajador
                  {' '}
                  <b>INDEPENDIENTE</b>
                  {' '}
                  (Utilizo boletas de honorarios como fuente principal de ingresos)
                </span>
              </MenuItem>
              <MenuItem value="DEPENDENT_PUBLIC" style={{ whiteSpace: 'normal' }}>
                <span>
                  Trabajo para una empresa o institución
                  {' '}
                  <b>PÚBLICA</b>
                  {' '}
                  (Municipalidad, ministerio, hospital, carabineros...)
                </span>
              </MenuItem>
              <MenuItem value="DEPENDENT_PRIVATE" style={{ whiteSpace: 'normal' }}>
                <span>
                  Trabajo para una empresa o institución
                  {' '}
                  <b>PRIVADA</b>
                  {' '}
                  (Tengo un contrato vigente y recibo un salario)
                </span>
              </MenuItem>
              <MenuItem value="UNEMPLOYED" style={{ whiteSpace: 'normal' }}>
                <span>
                  Estoy desempleado
                  o
                  no recibo un salario de manera formal
                </span>
              </MenuItem>
            </Select>
          </FormControl>
          {!!error
          && (
          <Typography color="error" variant="body2" marginTop={1} component="div">
            {error === 422
              ? (
                <div>
                  <div>
                    Pareciera faltar información sobre tus boletas de honorarios.
                    {' '}
                    <u style={{ cursor: 'pointer' }} aria-hidden onClick={handleTriggerClose}>Por favor presiona aquí</u>
                    {' '}
                    para volver a ingresar tu ClaveÚnica.
                  </div>
                  <div>
                    Si el problema persiste, ¡Por favor contáctanos!
                  </div>
                </div>
              )
              : 'Ha ocurrido un error. Por favor contáctanos.'}
          </Typography>
          )}
        </div>
  )}
      isTriggered={isTriggered}
      action={handleAction}
      button={<div />}
      hideCloseButton
      submitButtonLabel="CONFIRMAR"
      submitButtonDisabled={!workerType || !!error}
    />
  );
}
