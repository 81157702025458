import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import useFBPixeleventTracker from '../../utils/useFBPixelEventTracker';
import ErrorDialogContent from './content';

type ClaveUnicaErrorDialogProps = {
  errors:{
  isPasswordError: boolean,
  isDataExtractionError: boolean,
  isBlockedPasswordError: boolean,
  isMaintenanceError: boolean,
  isAFCBlockedPasswordError: boolean,
  isNotSubscribedError: boolean,
  isConnectionError: boolean,
  },
  numTries: number,
  retryCUSubmit: () => void,
  partner: string
}

export default function ClaveUnicaErrorDialog(props: ClaveUnicaErrorDialogProps) {
  const {
    errors,
    numTries,
    retryCUSubmit,
    partner,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [errorState, setErrorState] = React.useState<any[]>(['']);

  const handleClose = () => {
    setOpen(false);
  };
  const retry = () => {
    handleClose();
    retryCUSubmit();
  };
  React.useEffect(() => {
    if (Object.values(errors).some((err) => err)
    ) {
      if (errors.isPasswordError) {
        setErrorState(['passwordError', partner, undefined, numTries]);
      }
      if (errors.isBlockedPasswordError) {
        setErrorState(['blockedPassword', partner]);
      }
      if (errors.isMaintenanceError) {
        setErrorState(['maintenanceError', partner]);
      }
      if (errors.isAFCBlockedPasswordError) {
        setErrorState(['afcBlockedPassword', partner]);
      }
      if (errors.isNotSubscribedError) {
        setErrorState(['notSubscribedError', partner]);
      }
      if (errors.isConnectionError) {
        setErrorState(['connectionError', partner, retry]);
      }
      if (errors.isDataExtractionError) {
        setErrorState(['dataExtractionError', partner, undefined, numTries]);
      }
      setOpen(true);
    }
  }, [errors]);

  const errorLabel = () => {
    if (errors.isPasswordError) {
      return 'Error ingreso contraseña ClaveÚnica';
    }
    if (errors.isDataExtractionError) {
      return 'Error extracción de datos ClaveÚnica';
    }
    if (errors.isBlockedPasswordError) {
      return 'Contraseña bloqueada ClaveÚnica';
    }
    if (errors.isMaintenanceError) {
      return 'Servicios en mantención ClaveÚnica';
    }
    if (errors.isAFCBlockedPasswordError) {
      return 'Contraseña AFC bloqueada o sin crear ClaveÚnica';
    }
    if (errors.isNotSubscribedError) {
      return 'No suscrito en AFC ClaveÚnica';
    }
    if (errors.isConnectionError) {
      return 'Error conexión de ClaveÚnica';
    }
    return 'Error desonocido ClaveÚnica';
  };

  useFBPixeleventTracker('PLR V3', { event: errorLabel() });

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <b>{numTries > 2 ? 'Falta información' : 'Volver a ingresar ClaveÚnica'}</b>
      </DialogTitle>
      <DialogContent>
        {ErrorDialogContent({
          errorType: errorState[0],
          partner: errorState[1],
          retry: errorState[2],
          numTries: errorState[3],
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Entendido
        </Button>
      </DialogActions>
    </Dialog>
  );
}
