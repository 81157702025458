/* eslint-disable import/prefer-default-export */
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BricsaButton = styled(Button)<ButtonProps>(() => ({
  color: '#FFA751',
  border: '2px solid #FFA751',
  background: 'white',
  boxShadow: '0px 3px 1px -2px #FFA751, 0px 2px 2px 0px #FFA751, 0px 1px 5px 0px #FFA751',
  '&:hover': {
    backgroundColor: '#FFA751',
    color: 'white',
  },
}));

export const GoPlaceItButton = styled(Button)<ButtonProps>(() => ({
  color: '#012D74',
  border: '2px solid #012D74',
  background: 'white',
  boxShadow: '0px 3px 1px -2px #C4C4C4, 0px 2px 2px 0px #C4C4C4, 0px 1px 5px 0px #C4C4C4',
  '&:hover': {
    backgroundColor: '#E5EBF4',
    color: '#012D74',
  },
}));
