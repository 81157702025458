/* eslint-disable no-console */
import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { IntercomProvider } from 'react-use-intercom';
import useFBPixel from './utils/useFBPixel';
import AppRoutes from './navigation';
import { theme } from './theme';

const setSentryEnv = (url: string) => {
  if (url.includes('dev')) {
    return 'development';
  }
  if (url.includes('beta')) {
    return 'beta';
  }
  return 'production';
};
const setGTMArgs = (url: string) => {
  if (url.includes('dev') || url.includes('localhost')) {
    return 'GTM-PHLTNH6';
  }
  if (url.includes('beta')) {
    return 'GTM-N2MQN7C';
  }
  return 'GTM-PFR7PNK';
};

if (!window.location.href.includes('localhost')) {
  TagManager.initialize({ gtmId: setGTMArgs(window.location.href) });
} else {
  window.dataLayer = window.dataLayer || [];
  console.warn('GTM is deactivated for localhost!');
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: setSentryEnv(window.location.host),
  tracesSampleRate: 1.0,
});

function App() {
  useFBPixel();
  initMercadoPago(process.env.REACT_APP_MELI_PAYMENTS_PUBLIC_KEY as string, { locale: 'es-CL' });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID as string}>
        <AppRoutes />
      </IntercomProvider>
    </ThemeProvider>
  );
}

export default App;
