import React from 'react';
import {
  CircularProgress, Divider, Typography, useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../../../../utils/helpers';
import { FormSchemaV3, PostPreLoanRequestResponseV3, PLRStepV3 } from '../../../../../../../types/preloan/v3';
import { postPreLoanRequest, getInterestRate } from '../../../../../../../queries/preloan';
import useFBPixeleventTracker from '../../../../../../../utils/useFBPixelEventTracker';
import PLRAlertDialog from '../../../../../PLRAlertDialog';
import avatarExcited from '../../../../../../../assets/avatar_excited.svg';
import type { PLRAlertState } from '../../../../../../../types/preloan';
import { patchFillForm } from '../../../../../../../queries/preloan/v3';
import { partnerErrorRedirectURL } from '../../../formLogic';
import { handleDataOK, returnDataForm } from './helpers';

type GetOrCreatePLRProps = {
    formSchema: FormSchemaV3
    handleSetPLRState:(state:PLRStepV3|'error'|false)=>void,
    plrState: PLRStepV3|'error'|false,
    handleCurrentData: (values: FormSchemaV3)=> void,
    dataLoading: boolean,
    handleSetDataLoading: (state:boolean)=> void,
}

export default function GetOrCreatePLR(props:GetOrCreatePLRProps) {
  const {
    formSchema,
    handleCurrentData,
    plrState,
    handleSetPLRState,
    dataLoading,
    handleSetDataLoading,
  } = props;
  const theme = useTheme();
  const query = useQuery();
  const history = useHistory();
  const searchParams = React.useMemo(() => query, [query]);
  const [alertState, setAlertState] = React.useState<PLRAlertState>(false);
  const [signedLREmail, setSignedLREmail] = React.useState('');
  const getOrCreatePLR = async (
    origin: string,
    rut: string,
    captchaToken: string,
    personalInfo: FormSchemaV3['personalInfo'] | null = null,
    userInput: FormSchemaV3['userInput']| null = null,
    referralCode: string|null = null,
  ) => {
    let newPLRSentObject;
    const initialSentObject = {
      origin,
      rut,
      captchaToken,
      personalInfo: personalInfo && JSON.stringify(personalInfo),
      userInput: userInput && JSON.stringify({ ...userInput }),
    };
    if (referralCode) {
      newPLRSentObject = { ...initialSentObject, referralCode };
    } else {
      newPLRSentObject = initialSentObject;
    }

    try {
      const res = await postPreLoanRequest<
      PostPreLoanRequestResponseV3
      >(formSchema.id, newPLRSentObject);
      if (res.status === 206 && res.data.plr.email) {
        setSignedLREmail(res.data.plr.email);
        handleSetDataLoading(false);
        useFBPixeleventTracker(formSchema.product, { event: 'Alerta solicitud de crédito sin firmar' });
        window.dataLayer.push({
          event: 'Proceso onboarding',
          paso: 'RUT',
          subpaso: 'Consulta de datos',
          resultado: 'Usuario posee una solicitud de crédito activa sin firmar',
        });
        return setAlertState('userHasUnsignedLoanRequest');
      }
      searchParams.set('origin', res.data.plr.origin);
      searchParams.set('partner', res.data.plr.partner);
      history.push({
        search: searchParams.toString(),
      });
      const newData = {
        step: res.data.plr.step,
        PLRtoken: res.data.token,
        firstName: res.data.plr.firstName,
        lastName: res.data.plr.lastName,
        needsPensionStatement: res.data.plr.needsPensionStatement,
        needsSettlement: res.data.plr.needsSettlement,
        rut: res.data.plr.idNumber,
        origin: res.data.plr.origin,
        ...(res.data.preApprove && {
          preApprove: {
            accepted: res.data.preApprove?.accepted,
            id: res.data.preApprove?.id,
            maxAmount: res.data.preApprove?.maxAmount,
            productType: res.data.preApprove?.productType,
            rate: res.data.preApprove?.rate,
            maxPeriods: res.data.preApprove?.maxPeriods,
            maxInstallmentAmount: res.data.preApprove?.maxInstallmentAmount,
            currency: res.data.preApprove?.currency,
          },
        }),
        partner: res.data.plr.partner,
        partnerProducts: res.data.plr.partnerProducts,
      };
      if (res.data.plr.step === 'ARCHIVED_FAIL') {
        handleCurrentData({
          ...formSchema,
          ...newData,
          adminComment: res.data.plr.adminComment,
          results: res.data.results,
          debts: res.data.plr.debts,
        });
        return handleSetPLRState('ARCHIVED_FAIL');
      }
      handleCurrentData({
        ...formSchema,
        ...newData,
      });
      if (['NEEDS_CU', 'UPLOAD_DOCUMENTS', 'FILL_FORM'].includes(res.data.plr.step)) {
        if (res.data.plr.step === 'FILL_FORM') {
          const response = await patchFillForm(res.data.token);
          if (response.data.plr.step === 'DATA_OK') {
            return handleDataOK({
              token: res.data.token,
              newData,
              handleCurrentData,
              formSchema,
              handleSetPLRState,
            });
          }
          const { dataForm, contactForm } = returnDataForm({
            rawDataForm: response.data.form,
            phone: response.data.plr.phone,
            email: response.data.plr.email,
            formSchema,
          });
          handleCurrentData({
            ...formSchema, ...newData, contactForm, dataForm, step: response.data.plr.step,
          });
        }
        return handleSetPLRState(res.data.plr.step);
      }
      if (res.data.plr.step === 'DATA_OK') {
        return handleDataOK({
          token: res.data.token,
          newData,
          handleCurrentData,
          formSchema,
          handleSetPLRState,
        });
      }
      if (res.data.plr.step === 'LOAN_SIMULATION') {
        try {
          const interestRate = await getInterestRate(res.data.token);
          handleCurrentData({
            ...formSchema,
            ...newData,
            step: res.data.plr.step,
            loanParams: interestRate.data,
          });
          return handleSetPLRState(res.data.plr.step);
        } catch (e) {
          return handleSetPLRState('error');
        }
      }
      if (['COMPANY_CONTACT_INFO',
        'WAITING_COMPANY_AFFILIATION',
        'READY_FOR_LOAN',
        'COMPANY_APROVE',
        'ARCHIVED_SUCCESS',
        'NEEDS_ICP',
      ].includes(res.data.plr.step)) {
        handleCurrentData({
          ...formSchema,
          ...newData,
          step: res.data.plr.step,
        });
        return handleSetPLRState(res.data.plr.step);
      }
      return res;
    } catch (e) {
      if (e === 404) {
        handleSetDataLoading(false);
        useFBPixeleventTracker(formSchema.product, { event: 'Alerta usuario no posee datos personales' });
        window.dataLayer.push({
          event: 'Proceso onboarding',
          paso: 'RUT',
          subpaso: 'Consulta de datos',
          resultado: 'Datos personales de usuario no encontrados en servicio',
        });
        return setAlertState('userHasMissingPersonalInfo');
      }
      if (e === 412) {
        handleSetDataLoading(false);
        useFBPixeleventTracker(formSchema.product, { event: 'Alerta usuario tiene crédito activo' });
        window.dataLayer.push({
          event: 'Proceso onboarding',
          paso: 'RUT',
          subpaso: 'Consulta de datos',
          resultado: 'Usuario posee un crédito activo',
        });
        return setAlertState('userHasActiveLoan');
      }
      if (e === 413) {
        handleSetDataLoading(false);
        useFBPixeleventTracker(formSchema.product, { event: 'Alerta usuario tiene solicitud crédito firmada' });
        window.dataLayer.push({
          event: 'Proceso onboarding',
          paso: 'RUT',
          subpaso: 'Consulta de datos',
          resultado: 'Usuario posee una solicitud de crédito activa firmada',
        });
        return setAlertState('userHasSignedLoanRequest');
      }
      return handleSetPLRState('error');
    }
  };
  React.useEffect(
    () => {
      setTimeout(
        () => getOrCreatePLR(
          formSchema.origin,
          formSchema.rut,
          formSchema.captchaToken,
          undefined,
          Object.values(formSchema.userInput).some((uInput) => uInput === '') ? undefined : formSchema.userInput,
          formSchema.referralCode,
        ),
        1000,
      );
    },
    [],
  );
  React.useEffect(() => {
    if (plrState === 'error') {
      const redirectURL = partnerErrorRedirectURL(formSchema.origin, formSchema.rut);
      if (redirectURL) {
        setTimeout(() => { window.location.href = redirectURL; }, 1000);
      }
    }
  }, [plrState]);

  const handlePostPersonalInfo = (personalInfo: FormSchemaV3['personalInfo']) => {
    handleSetDataLoading(true);
    setAlertState(false);
    setTimeout(
      () => getOrCreatePLR(
        formSchema.origin,
        formSchema.rut,
        formSchema.captchaToken,
        personalInfo,
        Object.values(formSchema.userInput).some((uInput) => uInput === '') ? undefined : formSchema.userInput,
        formSchema.referralCode,
      ),
      2000,
    );
  };

  const getProduct = () => {
    if (formSchema.origin === 'tenpo') {
      return 'adelanto';
    }
    return 'crédito';
  };

  return (
    <>
      <div>
        <object data={avatarExcited} aria-labelledby="Avatar" height="80px" />
      </div>
      <Typography
        gutterBottom
        variant="h5"
        fontWeight="bold"
        align="center"
        alignSelf="center"
      >
        {!dataLoading ? 'Comprobación de datos' : 'Resultado preliminar'}

      </Typography>
      <Divider />
      {dataLoading ? (
        <>
          <br />
          <div>
            <Typography>
              Por favor espera un momento, esto podría tomar unos segundos...
            </Typography>
            <br />
            <CircularProgress size={20} />
          </div>
          {(plrState === 'error') && (
          <Typography variant="caption" color="error">
            Ha ocurrido un error, por favor intente en otra ocasión o
            contáctenos
            al servicio al cliente.
          </Typography>
          )}
        </>
      )
        : (
          <div style={{
            padding: '5%',
            marginTop: '5%',
            borderRadius: 15,
            backgroundColor: 'whitesmoke',
          }}
          >
            <Typography
              gutterBottom
              variant="h6"
            >
              {alertState ? '¡Hola!' : '¡Felicidades!'}

            </Typography>
            <Typography variant="h6" component="div">
              {alertState
                ? (
                  <div>
                    Según nuestros registros
                    existe un motivo
                    por el que
                    {' '}
                    <b style={{ color: theme.palette.secondary.main }}>
                      no puedes continuar
                      la evaluación crediticia con nosotros.
                    </b>
                    {' '}
                    Por favor sigue las instrucciones
                    indicadas en el detalle.
                  </div>
                )
                : (
                  <div
                    data-pw="preloan-get-or-create-text"
                    style={{ marginTop: 20 }}
                  >
                    ¡Vas por buen camino!
                    {' '}
                    <span style={{ color: theme.customPalette.tertiary.main }}>
                      Tu perfil pre-califica para un
                      {' '}
                      {getProduct()}
                      {formSchema.partnerProducts.some((product) => product === 'mortgage') ? ' hipotecario' : ' responsable'}
                      .
                    </span>
                    {' '}
                    A continuación te pediremos más información para conocerte mejor.
                  </div>
                )}
            </Typography>
            <br />
            {alertState
            && (
            <PLRAlertDialog
              alertState={alertState}
              email={signedLREmail}
              formSchema={formSchema}
              handlePostPersonalInfo={handlePostPersonalInfo}
            />
            )}
          </div>
        )}
    </>
  );
}
