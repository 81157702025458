import React from 'react';
import {
  Backdrop,
  CircularProgress,
  Fade,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import bancameLogoWhite from '../../assets/bancame_logo_white.svg';
import useStyles from './styles';
import JWTDecode from '../../utils/jwtDecode';
import { useQuery } from '../../utils/helpers';
import PreLoanRequestIndexV3 from '../../feature/preloan/v3';
import { patchOriginClick } from '../../queries/preloan/v3';

export default function PreLoanIndex() {
  const query = useQuery();
  const history = useHistory();
  const {
    boot: bootIntercom,
    shutdown: shutdownIntercom,
  } = useIntercom();
  const searchParams = React.useMemo(() => query, [query]);
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [preLoanId, setPreLoanId] = React.useState('');
  const [showForm, setShowForm] = React.useState(false);
  const [error, setError] = React.useState(false);
  const defaultOriginClickRes = { originLabel: 'web', partner: 'Banca.Me', partnerProducts: ['consumer, microLoan'] };
  const [origin, setOrigin] = React.useState(defaultOriginClickRes);

  const navigation = window.performance.getEntriesByType('navigation')[0].toJSON().type;

  const handleSetShowForm = (show: boolean) => {
    if (show) {
      window.dataLayer.push({ event: 'Inicio onboarding', paso: 'Bienvenida' });
    }
    setShowForm(show);
  };

  const originClick = async (params:{originIcomingData: string,
    click: boolean,
    token:string|null}) => {
    const { originIcomingData, click, token } = params;
    let originData: {originLabel: string,
      partner: string,
      partnerProducts: string[]} = defaultOriginClickRes;
    try {
      const res = await patchOriginClick(originIcomingData, click && !token);
      originData = res.data;
    } catch (e) {
      originData = defaultOriginClickRes;
    }
    searchParams.set('origin', originData.originLabel);
    searchParams.set('partner', originData.partner);
    if (originData.partner === 'Banca.Me') {
      bootIntercom();
    } else {
      shutdownIntercom();
    }
    if (token) {
      searchParams.set('token', token);
    }
    history.push({
      search: searchParams.toString(),
    });
    return setOrigin(originData);
  };

  React.useEffect(() => {
    const token = query.get('token');
    const originIcomingData = query.get('origin') || 'web';
    originClick({ originIcomingData, click: navigation === 'navigate', token });

    if (token) {
      setOpenBackdrop(true);
      try {
        const payload = JWTDecode(token);
        setPreLoanId(payload.preLoanRequestId);
        setOpenBackdrop(false);
        setShowForm(true);
      } catch (e) {
        setOpenBackdrop(false);
        setError(true);
      }
    }
  }, [query.get('origin')]);

  const getClassNameRightGrid = (originLead: string) => {
    switch (originLead) {
      case 'bricsa':
        return classes.rightGridBricsa;
      case 'goplaceit':
        return classes.rightGridGoplaceit;
      case 'fundamenta':
        return classes.rightGridFundamenta;
      case 'urmeneta':
        return classes.rightGridUrmeneta;
      default:
        return classes.rightGrid;
    }
  };

  return (
    <Grid
      container
      className={classes.root}
    >
      <Grid
        item
        xs={12}
        lg={5}
        component={Paper}
        className={classes.leftGrid}
        square
      >
        <PreLoanRequestIndexV3
          handleShowForm={handleSetShowForm}
          preLoanId={preLoanId}
          showForm={showForm}
          origin={origin}
        />
        {error && (
        <Typography color="error" style={{ margin: '30px 10%' }}>
          Ha ocurrido un error en la verificación, por favor comience de nuevo el flujo
          o contacte servicio al cliente
        </Typography>
        ) }
      </Grid>
      <Fade
        in
        {...({ timeout: 2500 })}
      >
        <Grid
          item
          xs={false}
          lg={7}
          className={getClassNameRightGrid(origin.originLabel)}
        >
          {!origin.partnerProducts.some((product) => product === 'mortgage') && (
            <div style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
            >

              <img src={bancameLogoWhite} width="250px" alt="BancaMeLogo" aria-hidden />
            </div>
          )}
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Fade>

    </Grid>

  );
}
