import React from 'react';
import { Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import AlertDialog from '../../../components/AlertDialog';
import { postCreatePrepayRequest } from '../../../queries/account';

type CreatePrepayRequestDialogProps ={
    token: string,
    pprSimulationError: boolean,
    loanId: string,
}
export default function CreatePrepayRequestDialog(props:CreatePrepayRequestDialogProps) {
  const { token, pprSimulationError, loanId } = props;
  const history = useHistory();
  const [postPPRError, setPOSTPPRError] = React.useState(false);
  const [pprLoading, setPPRLoading] = React.useState(false);
  const createPrepayRequest = async () => {
    setPPRLoading(true);
    try {
      await postCreatePrepayRequest(token, loanId);
      window.dataLayer.push({
        event: 'Solicitud de prepago total',
        boton: 'Continuar',
      });
      setTimeout(() => history.push(`/account/total-prepay/overview/${loanId}`), 1500);
    } catch (e) {
      setPOSTPPRError(true);
    }
  };
  return (
    <>
      <AlertDialog
        dialogTitle="¿Crear solicitud de prepago total?"
        dialogContent={(
          <>
            <Typography gutterBottom>
              Al presionar continuar,
              se enviará una solicitud de prepago a Banca.Me
            </Typography>
            {postPPRError && (
            <Typography
              color="error"
              variant="caption"
              component="div"
            >
              Ha ocurrido un error creando la solicitud de prepago, por favor contáctanos
            </Typography>
            )}
          </>
          )}
        dataLayer={{
          event: 'Solicitud de prepago total',
          boton: 'Crear solicitud',
        }}
        action={createPrepayRequest}
        button={(
          <Button
            disabled={pprSimulationError}
            variant="contained"
            style={{ marginTop: 50, marginBottom: 70 }}
          >
            Crear solicitud

          </Button>
          )}
        submitButtonDisabled={pprLoading}
        closeButtonDisabled={pprLoading}
      />
      {pprSimulationError && (
      <Typography color="error">
        Ha ocurrido un error cargando la simulación de prepago,
        por favor contáctanos
      </Typography>
      )}
    </>
  );
}
