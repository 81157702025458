import React from 'react';
import { Formik, Form } from 'formik';
import {
  CircularProgress, Divider, Typography, useTheme,
} from '@mui/material';
import type { PLRFormProps } from '../../../../../../types/preloan';
import { FormSchemaV3 } from '../../../../../../types/preloan/v3';
import UploadPLRDocuments from './components/UploadPLRDocuments';
import avatarConfident from '../../../../../../assets/avatar_finger.svg';
import { patchCheckDocuments, postPreApprove } from '../../../../../../queries/preloan/v3';
import { getInterestRate } from '../../../../../../queries/preloan';
import useFBPixeleventTracker from '../../../../../../utils/useFBPixelEventTracker';
import sendRiskEngineResultsToGTM from '../00_rejected/components/sendRiskEngineResultsToGTM';
import errorMessageSwitch from './components/errorMessageSwitch';
import { partnerErrorRedirectURL } from '../../formLogic';

export const gtmDocumentsSubpasoSwitch = (
  needsSettlement: boolean,
  needsPensionStatement: boolean,
) => {
  if (needsPensionStatement && needsSettlement) {
    return 'Carga de última liquidación y últimas 24 cotizaciones';
  }
  if (needsPensionStatement) {
    return 'Carga de última liquidación';
  }
  return 'Carga de últimas 24 cotizaciones';
};

export default function UploadDocumentsForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
    handleStep,
    handleCurrentData,
  } = props;
  const theme = useTheme();
  const [uploadOk, setUploadOk] = React.useState(false);
  const [resetUploadOk, setResetUploadOk] = React.useState(false);
  const [error, setError] = React.useState<React.ReactElement|null>(null);
  const [numTries, setNumTries] = React.useState(1);

  const handleUploadOk = async () => {
    setError(null);
    setUploadOk(true);
    handleEnableLoading(true);
    try {
      const { data, status } = await patchCheckDocuments(formSchema.PLRtoken, numTries);
      switch (data.plr.step) {
        case 'DATA_OK': {
          const { data: preApproveData } = await postPreApprove(formSchema.PLRtoken);
          switch (preApproveData.plr.step) {
            case 'LOAN_SIMULATION': {
              const interestRate = await getInterestRate(formSchema.PLRtoken);
              handleCurrentData({
                ...formSchema,
                step: data.plr.step,
                loanParams: interestRate.data,
              });
              return handleStep(5);
            }
            case 'READY_FOR_LOAN': {
              handleCurrentData({
                ...formSchema,
                step: data.plr.step,
                preApprove: {
                  accepted: preApproveData.preApprove?.accepted,
                  maxAmount: preApproveData.preApprove?.maxAmount,
                  productType: preApproveData.preApprove?.productType,
                  rate: preApproveData.preApprove?.rate,
                  maxPeriods: preApproveData.preApprove?.maxPeriods,
                },
              });
              return handleStep(9);
            }
            case 'ARCHIVED_FAIL':
              useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso ClaveÚnica a Rechazo del lead' });
              handleCurrentData({
                ...formSchema,
                step: 'ARCHIVED_FAIL',
                firstName: data.plr.firstName,
                lastName: data.plr.lastName,
                rut: data.plr.idNumber,
                results: preApproveData.results,
                origin: preApproveData.plr.origin,
              });
              sendRiskEngineResultsToGTM({
                origin: {
                  originLabel: formSchema.origin,
                  partner: formSchema.partner,
                  partnerProducts: formSchema.partnerProducts,
                },
                results: preApproveData.results,
                paso: 'Carga de documentos',
                subpaso: gtmDocumentsSubpasoSwitch(
                  formSchema.needsSettlement,
                  formSchema.needsPensionStatement,
                ),
              });
              return handleStep(['conquista', 'buyDepa', 'norteVerde', 'goplaceit'].includes(formSchema.origin) ? 9 : -1);
            default:
              return setError(
                <>
                  Ha ocurrido un error, por favor
                  intente en otra ocasión o contáctanos al servicio al cliente.
                </>,
              );
          }
        }
        case 'FILL_FORM': {
          const rawDataForm = data.form;
          const dataForm = {} as FormSchemaV3['dataForm'];
          const contactForm = {} as FormSchemaV3['contactForm'];
          Object.keys(rawDataForm).forEach((key) => {
            let value: string;
            if (key === 'phone') {
              value = data.plr.phone ? `+56 ${data.plr.phone}` : '+56 ';
              contactForm[key] = { value, type: rawDataForm[key], ok: true };
            } else if (key === 'email') {
              value = data.plr.email || '';
              contactForm[key] = { value, type: rawDataForm[key], ok: true };
            } else {
              value = formSchema.dataForm[key].value;
              dataForm[key] = { value, type: rawDataForm[key] };
            }
          });
          handleCurrentData({
            ...formSchema,
            firstName: data.plr.firstName,
            lastName: data.plr.lastName,
            step: data.plr.step,
            dataForm,
            contactForm,
          });
          if (status === 206) {
            const errorMessage = errorMessageSwitch({ status, numTries: 3 });
            setTimeout(() => handleStep(4), 10000);
            return setError(errorMessage.comp);
          }
          return handleStep(4);
        }
        case 'READY_FOR_LOAN': {
          handleCurrentData({
            ...formSchema,
            firstName: data.plr.firstName,
            lastName: data.plr.lastName,
            step: data.plr.step,
          });
          return handleStep(9);
        }
        default:
          return setError(
            <>
              Ha ocurrido un error, por favor
              intente en otra ocasión o contáctanos al servicio al cliente
            </>,
          );
      }
    } catch (e) {
      setUploadOk(false);
      const errorMessage = errorMessageSwitch({ status: e as string|number, numTries });
      if (['ERR_PAYSLIP_COULD_NOT_BE_PARSED', 'ERR_PENSION_STATEMENT_COULD_NOT_BE_PARSED', 'LAMBDA_TEXT_TO_STRUCTURED_ERROR'].includes(e as string)) {
        setNumTries((prevState) => prevState + 1);
      }
      setResetUploadOk(true);
      setUploadOk(false);
      window.dataLayer.push({ event: 'Error', paso: 'Carga de documentos', mensaje: errorMessage.str });
      return setError(errorMessage.comp);
    }
  };

  React.useEffect(() => {
    if (error) {
      const redirectURL = partnerErrorRedirectURL(formSchema.origin, formSchema.rut);
      if (redirectURL) {
        setTimeout(() => { window.location.href = redirectURL; }, 1000);
      }
    }
  }, [error]);

  React.useEffect(() => {
    if (resetUploadOk) {
      setTimeout(() => setResetUploadOk(false), 1000);
    }
  }, [resetUploadOk]);

  React.useEffect(() => handleEnableLoading(true), []);

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={() => { }}
    >
      {({
        values,
      }) => (
        <Form>
          <br />
          <div>
            <object data={avatarConfident} aria-labelledby="Avatar" height="80px" />
          </div>

          <Typography
            gutterBottom
            variant="h6"
            fontWeight="bold"
            align="center"
            alignSelf="center"
            data-pw="preloan-upload-documents-title"
          >
            Adjuntar documentos

          </Typography>
          <Divider />
          <br />
          <Typography>
            ¡Ya estás casi listo!
            Para continuar con tu evaluación, debes adjuntar
            {' '}
            {formSchema.needsSettlement && !formSchema.needsPensionStatement && (
            <b>
              tu última liquidación de sueldo
            </b>
            )}
            {formSchema.needsPensionStatement && !formSchema.needsSettlement && (
            <b>
              tus últimas
              24 cotizaciones de AFP.
            </b>
            )}
            {formSchema.needsPensionStatement && formSchema.needsSettlement
            && (
            <b>
              última liquidación de sueldo y tus últimas
              24 cotizaciones de AFP.
            </b>
            )}
          </Typography>
          <UploadPLRDocuments
            token={values.PLRtoken}
            handleUploadOk={handleUploadOk}
            buttonsDisplay="block"
            uploadDocumentsButtonVariant="outlined"
            cotizaciones={values.needsPensionStatement}
            liquidacion={values.needsSettlement}
            resetUploadOk={resetUploadOk}
            dataLayer={{ event: 'Proceso onboarding', paso: 'Carga de documentos' }}
            partner={values.partner}
          />
          <Divider style={{ marginTop: 5 }} />
          {uploadOk && !error && (
            <div style={{ backgroundColor: 'whitesmoke', padding: 10 }}>
              <Typography style={{ color: theme.palette.success.main }}>
                ¡Estamos analizando tus documentos!
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
              >
                Por favor espera un momento...
              </Typography>
              <CircularProgress size={20} />
            </div>
          )}
          {error && (
          <Typography variant="body2" color="error" marginTop="15px" component="div">
            {error}
          </Typography>
          )}
        </Form>
      )}

    </Formik>
  );
}
