import React from 'react';
import {
  Button,
  Fade,
  Dialog,
  DialogContent,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import avatarNervous from '../../../assets/avatar_nervous.svg';
import { deletePrepayRequest } from '../../../queries/account';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

type CancelPrepayRequestDialogProps = {
  prepayRequestId: string,
  token: string
}

export default function CancelPrepayRequestDialog(props: CancelPrepayRequestDialogProps) {
  const { prepayRequestId, token } = props;
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [cancelSuccess, setCancelSuccess] = React.useState(false);

  const handleClickOpen = () => {
    window.dataLayer.push({
      event: 'Solicitud de prepago total',
      boton: 'Cancelar solicitud',
    });
    setOpen(true);
  };

  const handleClose = () => {
    window.dataLayer.push({
      event: 'Solicitud de prepago total',
      boton: 'Volver a solicitud',
    });
    setOpen(false);
  };

  const cancelPPR = async () => {
    setLoading(true);
    try {
      await deletePrepayRequest(token, prepayRequestId);
      window.dataLayer.push({
        event: 'Solicitud de prepago total',
        boton: 'Confirmar cancelar solicitud',
      });
      setCancelSuccess(true);
      setTimeout(() => history.push('/account'), 1500);
    } catch (e) {
      setError(true);
    }
  };

  return (
    <>
      <Button
        color="secondary"
        onClick={handleClickOpen}
        sx={{ margin: { xs: 2, lg: 'auto 5px' } }}
      >
        Cancelar solicitud
      </Button>
      <Dialog
        className={classes.Dialog}
        open={open}
        TransitionComponent={Transition}
        style={{ textAlign: 'center', margin: 0 }}
        maxWidth="lg"
      >

        <DialogContent>
          <Fade
            in
            {...({ timeout: 2500 })}
          >
            <div style={{ height: '140px' }}>
              <img src={avatarNervous} width="90px" alt="AvatarNervous" />
            </div>
          </Fade>
          <Typography
            variant="h5"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            Cancelar solicitud

          </Typography>

          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            gutterBottom
          >
            ¿Estás seguro que deseas cancelar la solicitud?

          </Typography>
          <Typography
            style={{ margin: '3% auto', maxWidth: '350px' }}
            variant="body1"
            color="text.secondary"
            align="center"
            gutterBottom
          >
            Puedes volver a crear una sin ningún problema y
            generarla nuevamente cuando estimes conveniente

          </Typography>
          {cancelSuccess && <Typography variant="caption" color="green" component="div">Se ha cancelado la solicitud de prepago con éxito, volviendo a la página principal...</Typography>}
          {error && <Typography variant="caption" color="error" component="div">Ha ocurrido un error cancelando tu solicitud de prepago, por favor contáctanos</Typography>}
          <Button variant="contained" style={{ margin: 5 }} disabled={loading} onClick={cancelPPR}>Sí</Button>
          <Button variant="outlined" style={{ margin: 5 }} disabled={loading} onClick={handleClose}>No</Button>
        </DialogContent>

      </Dialog>
    </>
  );
}
