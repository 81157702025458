import fetcher from '../../utils/fetcher';
import type { LoginMember } from './types';

const loginBody = (endpoint: '/login/member'|'/login/admin', identification: string, password: string) => {
  switch (endpoint) {
    case '/login/member':
      return { rut: identification, password };
    case '/login/admin':
      return { email: identification, password };
    default:
      return null;
  }
};

export default async function postLoginMember(
  endpoint: '/login/member'|'/login/admin',
  identification: string,
  password: string,
) {
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}${endpoint}`,
    'POST',
    loginBody(endpoint, identification, password),
    null,
  ) as LoginMember;
  return {
    id: res.data.id,
    token: res.data.token,
    email: res.data.email,
    firstName: res.data.firstName,
    role: res.data.role,
    hasAddress: res.data.hasAddress,
    hasLoan: res.data.hasLoan,
    hasInvestment: res.data.hasInvestment,
  };
}
