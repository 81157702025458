import React from 'react';
import { Button, Typography } from '@mui/material';

type NegotiableDebtsProps={
    hasNegotiableDebts: boolean
    idNumber:string,
    firstName:string,
    lastName: string,
}

const setPLRData = (
  idNumber:string,
  firstName:string,
  lastName: string,
) => {
  window.dataLayer.push({
    event: 'Acciones',
    accion: 'Ver mis deudas',
  });
  localStorage.setItem('PLR', JSON.stringify({ idNumber, firstName, lastName }));
  window.open('/products');
};

export default function NegotiableDebts(props:NegotiableDebtsProps) {
  const {
    hasNegotiableDebts,
    idNumber,
    firstName,
    lastName,
  } = props;

  return (
    <div style={{ marginBlock: '4%' }}>
      {hasNegotiableDebts ? (
        <div style={{ maxHeight: '40px', marginTop: '20px', marginBottom: '2%' }}>
          <Typography gutterBottom align="center" variant="body2">
            ¡Aún podemos ayudarte!
          </Typography>
          <Typography gutterBottom align="center" variant="body2">

            Tu deuda
            {' '}
            <b>
              <u>califica para una mejora.</u>
            </b>
          </Typography>
        </div>
      ) : (
        <Typography gutterBottom align="center" variant="body2" fontWeight="bold" style={{ textDecoration: 'underline' }}>
          ¿Cómo te podemos ayudar?
        </Typography>
      )}
      <div style={{
        marginTop: hasNegotiableDebts ? undefined : 15,
      }}
      >
        <Button
          onClick={() => setPLRData(idNumber, firstName, lastName)}
          variant="contained"
        >
          Ver mis deudas
        </Button>
      </div>
    </div>
  );
}
