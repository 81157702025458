import React from 'react';
import {
  Button,
  Fade,
  Grid,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import avatar from '../../../assets/avatar_finger.svg';
import useStyles from './styles';
import ViewTitle from '../../../components/ViewTitle';

export default function PartialPrepay() {
  const classes = useStyles();
  const history = useHistory();

  const backToAccount = () => {
    history.push('/account');
  };
  const goToReduceInstallments = () => {
    window.dataLayer.push({
      event: 'Solicitud de prepago parcial',
      boton: 'Reducir número de cuotas',
    });
    window.open('https://wa.me/56920711980?text=¡Hola!%20Quiero%20reducir%20el%20número%20de%20cuotas%20de%20mi%20crédito%20con%20Banca.Me.', '_blank');
  };
  const goToReduceAmount = () => {
    window.dataLayer.push({
      event: 'Solicitud de prepago parcial',
      boton: 'Reducir monto de cada cuota',
    });
    window.open('https://wa.me/56920711980?text=¡Hola!%20Quiero%20reducir%20el%20monto%20de%20cada%20cuota%20de%20mi%20crédito%20con%20Banca.Me.', '_blank');
  };
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        rowGap={5}
      >
        <Grid
          item
          xs={12}
          lg={12}
        >
          <div
            className={classes.backButton}
            onClick={backToAccount}
            aria-hidden
          >
            <ArrowBackIcon style={{ fontSize: '18px', margin: 'auto 8px' }} />
            Volver
          </div>

        </Grid>
        <ViewTitle
          title="Prepago parcial"
          color="secondary"
          variant="h4"
        />
        <Fade
          in
          {...({ timeout: 2500 })}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            textAlign="center"
          >
            <img src={avatar} alt="Avatar" className={classes.prepayAvatar} />
            <div className={classes.actionButtons} style={{ width: '100%', marginBottom: 20 }}>
              <Typography
                variant="h5"
                fontWeight="bold"
                gutterBottom
              >
                Quiero:

              </Typography>
              <Button
                style={{ margin: 5 }}
                variant="contained"
                onClick={goToReduceAmount}
              >
                Reducir monto de cada cuota

              </Button>
              <Button
                style={{ margin: 5 }}
                variant="contained"
                onClick={goToReduceInstallments}
              >
                Reducir número de cuotas

              </Button>
            </div>
            <Typography
              variant="body1"
              gutterBottom
            >
              Si quieres pagar tu crédito más rápido y tienes la capacidad mensual para hacerlo,
              puedes disminuir el número de cuotas a pagar o
              reducir el monto que pagas en cada cuota.
            </Typography>
            <div className={classes.footerInfo} style={{ width: '100%' }}>
              <div
                className={classes.footerIcon}
                style={{ placeContent: 'center' }}
              >
                <InfoOutlinedIcon style={{ fontSize: '20px' }} />
              </div>
              <Typography
                display="inline"
                variant="body2"
                fontWeight="light"
                className={classes.footerText}
              >
                Para prepagar tu crédito con Banca.Me debes prepagar al
                menos un 25% de la deuda que tienes actualmente con nosotros.
              </Typography>
            </div>
          </Grid>
        </Fade>
      </Grid>
    </div>
  );
}
