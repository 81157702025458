import React from 'react';
import {
  Typography,
  Link,
  Checkbox,
  FormControlLabel,
  Alert,
  Snackbar,
} from '@mui/material';
import { mutate } from 'swr';
import AlertDialog from '../../../components/AlertDialog';
import { patchClientOptIn } from '../../../queries/account';

type AcceptOptInDialogProps ={
    token: string;
}

export default function AcceptOptInDialog(props: AcceptOptInDialogProps) {
  const { token } = props;
  const [checked, setChecked] = React.useState<boolean>(false);
  const [warning, setWarning] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const patchAcceptOptIn = async () => {
    setLoading(true);
    try {
      await patchClientOptIn(token);
      window.dataLayer.push({
        event: 'Navegación',
        boton: 'Aceptar términos y condiciones de Programa de Referidos',
      });
      mutate([`${process.env.REACT_APP_API_URL}/client/referral/info`, 'GET', null, token]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (e) {
      setWarning('Ha ocurrido un error creando tus datos para el Programa de Referidos');
      setLoading(false);
    }
  };
  return (
    <>
      <AlertDialog
        dialogTitle="Programa de Referidos"
        dialogContent={(
          <>
            <Typography align="left" color="text.secondary">
              Para participar y ganar en el programa de referidos necesitamos
              que aceptes los
              {' '}
              <Link style={{ textDecoration: 'underline' }} href="/referral-terms-and-conditions" target="_blank" rel="noopener">
                términos y condiciones
              </Link>
              {' '}
              previo a entregarte tu link y código
            </Typography>
            <Typography align="left" color="text.secondary">
              Marcando el check a continuación y aceptando ¡Ya estarás participando!
            </Typography>
            <FormControlLabel
              style={{ marginTop: '25px' }}
              control={(
                <Checkbox
                  size="small"
                  checked={checked}
                  onClick={() => setChecked((o) => !o)}
                />
)}
              label={(
                <Typography variant="caption">
                  Acepto los términos y condiciones
                </Typography>
)}
            />
          </>
)}
        action={patchAcceptOptIn}
        submitButtonLabel="Acepto"
        maxWidth="xs"
        buttonLabel="Ver condiciones"
        buttonVariant="contained"
        closeAfterSubmit
        submitButtonDisabled={!checked || loading}
      />
      <Snackbar open={!!warning} autoHideDuration={3000} onClose={() => { setWarning(''); }}>
        <Alert severity="warning">{warning}</Alert>
      </Snackbar>
    </>
  );
}
