import { postPreApprove } from '../../../../../../../queries/preloan/v3';
import { getInterestRate } from '../../../../../../../queries/preloan';
import useFBPixeleventTracker from '../../../../../../../utils/useFBPixelEventTracker';
import { FormSchemaV3, PLRStepV3, PatchFillFormResponse } from '../../../../../../../types/preloan/v3';

// eslint-disable-next-line import/prefer-default-export
export const handleDataOK = async (params: {token: string,
    newData: {},
    handleCurrentData: (values: FormSchemaV3)=> void,
    formSchema: FormSchemaV3,
    handleSetPLRState: (state:PLRStepV3|'error'|false)=>void,
}) => {
  const {
    token, newData, handleCurrentData,
    formSchema, handleSetPLRState,
  } = params;
  const { data: preApproveData } = await postPreApprove(token);
  switch (preApproveData.plr.step) {
    case 'LOAN_SIMULATION': {
      const interestRate = await getInterestRate(token);
      handleCurrentData({
        ...formSchema,
        ...newData,
        step: preApproveData.plr.step,
        loanParams: interestRate.data,
        origin: preApproveData.plr.origin,
      });
      return handleSetPLRState(preApproveData.plr.step);
    }
    case 'READY_FOR_LOAN': {
      handleCurrentData({
        ...formSchema,
        step: preApproveData.plr.step,
        ...(preApproveData.preApprove && {
          preApprove: {
            accepted: preApproveData.preApprove.accepted,
            id: preApproveData.preApprove.id,
            maxAmount: preApproveData.preApprove.maxAmount,
            maxInstallmentAmount: preApproveData.preApprove.maxInstallmentAmount,
            maxPeriods: preApproveData.preApprove.maxPeriods,
            productType: preApproveData.preApprove.productType,
            rate: preApproveData.preApprove.rate,
            currency: preApproveData.preApprove.currency,
          },
        }),
      });
      return handleSetPLRState(preApproveData.plr.step);
    }
    case 'ARCHIVED_FAIL':
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Rechazo del lead' });
      handleCurrentData({
        ...formSchema,
        ...newData,
        step: preApproveData.plr.step,
        firstName: preApproveData.plr.firstName,
        lastName: preApproveData.plr.lastName,
        rut: preApproveData.plr.idNumber,
        results: preApproveData.results,
        origin: preApproveData.plr.origin,
      });
      return handleSetPLRState(preApproveData.plr.step);
    default:
      return handleSetPLRState('error');
  }
};

export const returnDataForm = (params:{
  rawDataForm: PatchFillFormResponse['data']['form'],
  phone: string|undefined,
  email: string|undefined,
  formSchema: FormSchemaV3,
}) => {
  const {
    rawDataForm, phone, email, formSchema,
  } = params;
  const dataForm = {} as FormSchemaV3['dataForm'];
  const contactForm = {} as FormSchemaV3['contactForm'];
  Object.keys(rawDataForm).forEach((key) => {
    let value: string;
    if (key === 'phone') {
      value = phone ? `+56 ${phone}` : '+56 ';
      contactForm[key] = { value, type: rawDataForm[key], ok: true };
    } else if (key === 'email') {
      value = email || '';
      contactForm[key] = { value, type: rawDataForm[key], ok: true };
    } else {
      value = formSchema.dataForm[key].value;
      dataForm[key] = { value, type: rawDataForm[key] };
    }
  });
  return { dataForm, contactForm };
};

export const handlePLRstateSwitch = (params:{
    plrState: PLRStepV3|false|'error',
    formSchema: FormSchemaV3,
    handleStep: (step: number) => void,
    handleSetDataLoading: (state: boolean) => void,
    handleEnableLoading: (state: boolean) => void,
    isPrevired?: boolean,
}) => {
  const {
    plrState, formSchema,
    handleStep,
    handleSetDataLoading, handleEnableLoading,
    isPrevired = false,
  } = params;
  switch (plrState) {
    case 'ARCHIVED_FAIL': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'RUT',
        subpaso: 'Consulta de datos',
        resultado: 'Rechazado',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Rechazo del lead' });
      return handleStep(['conquista', 'buyDepa', 'norteVerde'].includes(formSchema.origin) ? 9 : -1);
    }
    case 'NEEDS_CU': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Proceso onboarding',
        paso: 'RUT',
        subpaso: 'Consulta de datos',
        resultado: 'Pre-califica',
      });
      handleEnableLoading(false);
      return handleSetDataLoading(false);
    }
    case 'UPLOAD_DOCUMENTS': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'Oferta',
        subpaso: 'Consulta de datos',
        estado: 'Subir documentos',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Subir documentos' });
      return handleStep(3);
    }
    case 'FILL_FORM': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'Oferta',
        subpaso: 'Consulta de datos',
        estado: 'Rellenar formulario',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Rellenar formulario' });
      return handleStep(4);
    }
    case 'COMPANY_CONTACT_INFO': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'Oferta',
        subpaso: 'Solicitar aprobación',
        estado: 'DPP aprobado sin convenio',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Datos de contacto empresa' });
      return handleStep(6);
    }
    case 'LOAN_SIMULATION': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'Oferta',
        subpaso: 'Aceptar oferta',
        estado: 'DPP con convenio/PAC aprobado con firma automática',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Simulación crédito' });
      return handleStep(5);
    }
    case 'NEEDS_ICP': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'Previred',
        subpaso: 'Extraer datos',
        estado: 'Lead esperando autenticación previred',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Consulta Previred' });
      return handleStep(8);
    }
    case 'READY_FOR_LOAN': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'Oferta',
        subpaso: 'Aceptar oferta',
        estado: 'DPP con convenio/PAC aprobado sin firma automática',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Cierre, simulación crédito con revisión por agente' });
      return handleStep(9);
    }
    case 'WAITING_COMPANY_AFFILIATION': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'Oferta',
        subpaso: 'Solicitar aprobación',
        estado: 'DPP sin convenio, esperando afiliación con empresa',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Cierre, esperando afiliación empresa' });
      return handleStep(9);
    }
    case 'COMPANY_APROVE': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'Oferta',
        subpaso: 'Aceptar oferta',
        estado: 'Lead esperando aprobación empleador',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Cierre, lead esperando aprobación empleador' });
      return handleStep(9);
    }
    case 'ARCHIVED_SUCCESS': {
      window.dataLayer.push({
        event: isPrevired ? 'Consulta Previred' : 'Retomar onboarding',
        paso: 'Oferta',
        subpaso: 'Aceptar oferta',
        estado: 'Lead archivado como exitoso',
      });
      useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso RUT a Cierre, lead archivado como exitoso' });
      return handleStep(9);
    }
    default:
      return handleSetDataLoading(true);
  }
};
