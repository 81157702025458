import React from 'react';
import { Formik, Form } from 'formik';
import { Typography } from '@mui/material';
import type { PLRFormProps } from '../../../../../../types/preloan';
import type { FormSchemaV3, PostCompanyInfoResponse } from '../../../../../../types/preloan/v3';
import CompanyContactForm from './components/CompanyContactForm';
import { patchPLRStep, postPLRHubspotForm } from '../../../../../../queries/preloan/v2';
import { postCompanyInfo } from '../../../../../../queries/preloan';
import useFBPixeleventTracker from '../../../../../../utils/useFBPixelEventTracker';
import { companyDataValidationSchema } from '../../../validationSchemas';
import { partnerErrorRedirectURL } from '../../formLogic';

export default function CompanyInfoForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
    handleStep,
    handleCurrentData,
  } = props;
  const [error, setError] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const handleSetChecked = () => setChecked((o) => !o);
  const archivePLR = async (isRejectedBySimulator: boolean = false) => {
    let rejectReason = 'La persona solicitante no permite el contacto con su empleador para autorizar descuento por planilla.';
    if (isRejectedBySimulator) {
      rejectReason = 'No tenemos un crédito que se adapte a tus capacidades de pago (que no superen el 15% de descuento sobre tu sueldo)';
    }
    await patchPLRStep('ARCHIVED_FAIL', [rejectReason], formSchema.PLRtoken);

    handleCurrentData({
      ...formSchema,
      adminComment: `RECHAZO AUTOMÁTICO: ${rejectReason}`,
    });
    window.dataLayer.push({
      event: 'Usuario rechazado',
      paso: 'Oferta',
      subpaso: 'Ingreso de datos empleador',
      resultado: `RECHAZO AUTOMÁTICO: ${rejectReason}`,
    });
    handleStep(-1);
  };

  React.useEffect(() => {
    if (checked) {
      return handleEnableLoading(false);
    }
    return handleEnableLoading(true);
  }, [checked]);

  React.useEffect(() => {
    if (error) {
      const redirectURL = partnerErrorRedirectURL(formSchema.origin, formSchema.rut);
      if (redirectURL) {
        setTimeout(() => { window.location.href = redirectURL; }, 1000);
      }
    }
  }, [error]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      validationSchema={companyDataValidationSchema}
      onSubmit={async (values) => {
        try {
          handleEnableLoading(true);
          setError(false);
          const CCFirstName = values.companyContactName.split(' ')[0];
          const CCLastName = values.companyContactName.split(' ').length > 1
            ? values.companyContactName.split(' ').slice(1).join(' ')
            : '';
          await postPLRHubspotForm({
            companyName: values.companyContactName,
            companyContactEmail: values.companyContactMail,
            companyContactPhone: values.companyContactPhone,
            companyContactFirstName: CCFirstName,
            companyContactLastName: CCLastName,
            applicantFirstName: values.firstName,
            applicantLastName: values.lastName,
            origin: values.origin,
            applicantRut: values.rut,
          }, formSchema.requestLoanData);

          const companyPhoneCode = values.companyContactPhone.slice(0, 3);
          const companyPhoneNumber = values.companyContactPhone.slice(4);
          const res = await postCompanyInfo<PostCompanyInfoResponse>({
            companyName: values.companyName,
            companyEmail: values.companyContactMail,
            companyPhoneCode,
            companyPhoneNumber,
            companyContactName: values.companyContactName,
          }, values.PLRtoken);
          useFBPixeleventTracker(formSchema.product, { event: 'Ingreso a cierre desde Datos contacto empres a esperando afiliación empresa' });
          handleCurrentData({ ...formSchema, step: res.data.plr.step });
          window.dataLayer.push({
            event: 'Proceso onboarding',
            paso: 'Oferta',
            subpaso: 'Ingreso de datos empleador',
          });
          return handleStep(9);
        } catch (e) {
          handleEnableLoading(false);
          return setError(true);
        }
      }}
    >
      {({
        values,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        errors,
      }) => (
        <Form>
          <CompanyContactForm
            archivePLR={archivePLR}
            formSchema={values}
            checked={checked}
            handleSetChecked={handleSetChecked}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            touched={touched}
            errors={errors}
          />
          {error && (
          <Typography variant="caption" color="error">
            Ha ocurrido un error, por favor intente en otra ocasión o contáctenos
            al servicio al cliente.
          </Typography>
          )}
        </Form>

      )}

    </Formik>
  );
}
