/* eslint-disable camelcase */
import React from 'react';
import {
  Divider,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import type { PLRFormProps } from '../../../../../../types/preloan';
import { FormSchemaV3 } from '../../../../../../types/preloan/v3';
import Header from './components/Header';
import ResultLabels from './components/ResultsLabels';
import NoFilterResults from './components/NoFilterResults';
import NegotiableDebts from './components/NegotiableDebts';
import { getRedirectUrl } from '../07_lastStep/components/messageSwitches';
import { getOriginButtonLabel } from '../../formLogic';

export default function RejectedForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
  } = props;
  const [hasNegotiableDebts, setHasNegotiabeDebts] = React.useState(false);

  const handleHasNegotiableDebts = () => {
    if (formSchema.debts && formSchema.debts.length > 0) {
      window.dataLayer.push({
        event: 'Usuario rechazado',
        paso: 'RUT',
        subpaso: 'Consulta de datos',
        resultado: 'Tiene deuda negociable',
      });
      return setHasNegotiabeDebts(true);
    }
    return setHasNegotiabeDebts(false);
  };

  React.useEffect(() => {
    handleHasNegotiableDebts();
    handleEnableLoading(false);
  }, []);
  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={(values) => {
        window.dataLayer.push({ event: 'Acciones', paso: 'RUT', accion: getOriginButtonLabel(formSchema.origin) });
        window.location.href = getRedirectUrl(values.origin, false, formSchema.rut);
      }}
    >
      {({
        values,
      }) => (
        <Form>
          <Header
            formSchema={values}
          />
          <div style={{
            maxHeight: '100%',
            marginTop: '20px',
            marginBottom: '5%',
          }}
          >
            <Typography
              gutterBottom
              variant="body2"
              fontWeight="bold"
            >
              ¿Cuáles son los motivos?
            </Typography>
            <Divider />
            <div style={{
              overflowY: 'auto',
              maxHeight: '200px',
              margin: 'auto',
              backgroundColor: 'whitesmoke',
            }}
            >
              {values.results && values.results.length > 0
            && (
            <Typography
              variant="body2"
              style={{ textDecoration: 'underline' }}
            >
              Resultados evaluación
            </Typography>
            )}
              <ResultLabels
                results={values.results}
                origin={{
                  originLabel: values.origin,
                  partner: values.partner,
                  partnerProducts: values.partnerProducts,
                }}
              />
            </div>
            {(!values.results || values.results?.length === 0)
          && (
          <NoFilterResults
            adminComment={values.adminComment}
          />
          )}
          </div>
          {!values.partnerProducts.some((product) => product === 'mortgage' || formSchema.origin === 'tenpo')
      && (
      <NegotiableDebts
        hasNegotiableDebts={hasNegotiableDebts}
        idNumber={values.rut}
        firstName={values.firstName}
        lastName={values.lastName}
      />
      )}

        </Form>
      )}
    </Formik>
  );
}

RejectedForm.defaultProps = {
  debts: undefined,
};
