/* eslint-disable camelcase */
import React from 'react';
import {
  Backdrop, Button, CircularProgress, Typography, useTheme,
} from '@mui/material';
import { getFintoc } from '@fintoc/fintoc-js';
import BancameAlert from '../Alert/index';
import { getFintocSubscription, postFintocSubscription } from '../../queries/account';
import useStyles from './styles';

type FintocEventGTMLabels = {
  [key:string]:string,
  on_available_institutions: string,
  on_authentication_form: string,
  creating_link: string,
  selecting_account: string,
  creating_subscription: string,
  app_authentication_required: string,
  card_authentication_required: string,
  device_authentication_required: string,
  sms_authentication_required: string,
  email_authentication_required: string,
  captcha_authentication_required: string,
  on_error: string,
  open: string,
};

const fintocEventGTMLabels: FintocEventGTMLabels = {
  on_available_institutions: 'Seleccionar institución',
  on_authentication_form: 'Inciar sesión',
  creating_link: 'Crear link de inicio de sesión',
  selecting_account: 'Selección de cuenta',
  creating_subscription: 'Creando subscripción',
  app_authentication_required: 'Autenticación mediante app',
  card_authentication_required: 'Autenticación mediante tarjeta de coordenadas',
  device_authentication_required: 'Autenticación mediante dispositivo',
  sms_authentication_required: 'Autenticación mediante SMS',
  email_authentication_required: 'Autenticación mediante correo electrónico',
  captcha_authentication_required: 'Autenticación mediante captcha',
  on_error: 'Tuvimos un problema conectándonos',
  open: 'Apertura widget',
};
const getFintocGTMEventLabel = (value:string) => {
  if (fintocEventGTMLabels[value]) {
    return fintocEventGTMLabels[value];
  }
  return value;
};

type FintocWidgetProps = {
    idNumber: string,
    token: string,
}

const notSubscribedStates = ['INTENT', 'CANCELED'];
export default function FintocWidget(props: FintocWidgetProps) {
  const {
    idNumber,
    token,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [isAlreadySubscribed, setIsAlreadySubscribed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [widgetToken, setWidgetToken] = React.useState<string|undefined>(undefined);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('¡Pago (PAC) configurado correctamente!');

  const getWidgetToken = async () => {
    setError(false);
    setOpenAlert(false);
    setLoading(true);
    try {
      const res = await postFintocSubscription(token);
      setLoading(false);
      if (!notSubscribedStates.includes(res.subscriptionStatus)) {
        return setIsAlreadySubscribed(true);
      }
      return setWidgetToken(res.widgetToken);
    } catch (e) {
      window.dataLayer.push({
        event: 'Error',
        paso: 'Cuenta personal, Suscripción PAC',
        mensaje: 'Ha ocurrido un error, por favor contáctanos',
      });
      setError(true);
      return setOpenAlert(true);
    }
  };
  const getSubscriptionState = async () => {
    setError(false);
    setOpenAlert(false);
    setLoading(true);
    try {
      const res = await getFintocSubscription(token);
      if (!notSubscribedStates.includes(res.subscriptionStatus)) {
        setLoading(false);
        return setIsAlreadySubscribed(true);
      }
      return setTimeout(async () => {
        await getSubscriptionState();
      }, 2000);
    } catch (e) {
      setError(true);
      return setOpenAlert(true);
    }
  };

  const options = {
    holderType: 'individual',
    product: 'subscriptions',
    publicKey: process.env.REACT_APP_FINTOC_PUBLIC_KEY,
    onExit: async () => {
      setSuccessMessage('Si no configuras PAC, no se te podrá transferir tu producto');
      window.dataLayer.push({
        event: 'Error',
        paso: 'Cuenta personal, Suscripción PAC',
        mensaje: 'Si no configuras PAC, no se te podrá transferir el crédito',
      });
      setError(true);
      setOpenAlert(true);
      await getWidgetToken();
    },
    onSuccess: async () => {
      setSuccessMessage('¡PAC configurado correctamente!');
      setOpenAlert(true);
      window.dataLayer.push({ event: 'PAC configurado exitosamente' });
      await getSubscriptionState();
    },
    username: ['dev', 'beta', 'localhost'].some((env) => process.env.REACT_APP_API_URL?.includes(env)) ? undefined : idNumber,
    onEvent: async (event: string) => {
      if (event === 'on_error') {
        return window.dataLayer.push({
          event: 'Error',
          paso: 'Cuenta personal, Suscripción PAC',
          mensaje: getFintocGTMEventLabel(event),
        });
      }
      return window.dataLayer.push({
        event: 'Acciones',
        paso: 'Cuenta personal, Suscripción PAC',
        accion: getFintocGTMEventLabel(event),
      });
    },
  };
  const openWidget = async () => {
    window.dataLayer.push({
      event: 'Acciones',
      paso: 'Cuenta personal, Suscripción PAC',
      accion: 'PAC',
    });
    const Fintoc = await getFintoc();
    const widget = Fintoc?.create({ ...options, widgetToken });
    return widget?.open();
  };

  React.useEffect(() => {
    getWidgetToken();
  }, []);

  return (
    <>
      {!isAlreadySubscribed ? (
        <div style={{ margin: 20 }}>
          <Typography variant="body2" align="center" gutterBottom>
            Estimado usuario, para acceder a nuestros productos de financiamiento es necesario
            {' '}
            <b style={{ color: theme.palette.secondary.main }}>
              suscribir un PAC (Pago Automático de Cuentas)
            </b>
            {' '}
            para recibir tu crédito.
          </Typography>
          <Typography variant="body2" align="center">
            Debes tener a mano tus credenciales bancarias
            (e.g. tarjeta de coordenadas o digipass). Haz click en
            {' '}
            <b style={{ color: theme.palette.secondary.main }}>Asociar PAC</b>
            {' '}
            para comenzar el proceso.
          </Typography>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Button
              disabled={loading}
              onClick={openWidget}
              variant="contained"
              color="primary"
              style={{ marginTop: '20px' }}
            >
              Asociar PAC
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ margin: 20 }}>
          <Typography variant="body2" align="center" gutterBottom>
            Estimado usuario,
            {' '}
            <b style={{ color: theme.palette.secondary.main }}>
              ¡ya tienes tu PAC configurado!
            </b>
          </Typography>
          <Typography variant="body2" align="center">
            Ya estas listo por ahora
            {' '}
            <b style={{ color: theme.palette.secondary.main }}>
              ¡solo queda seguir disfrutando nuestros productos!
            </b>
          </Typography>
        </div>
      )}
      <BancameAlert
        successMessage={successMessage}
        errorMessage="Ha ocurrido un error, por favor contáctanos"
        openAlert={openAlert}
        error={error}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <div>
          <CircularProgress
            color="inherit"
          />
        </div>
        <b style={{ marginLeft: 20 }}>
          Cargando, espera un momento...
        </b>
      </Backdrop>
    </>
  );
}
