import React from 'react';
import {
  Button,
  Fade,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { FormikProps } from 'formik';
import * as Sentry from '@sentry/react';
import LastStepForm from './steps/07_lastStep';
import QontoStepIcon from '../../../../components/QontoStepIcon';
import {
  formSchema, stepperSteps, steps, getButtonLabel, getStepContent,
} from './formLogic';
import { QontoConnector } from '../../../../components/QontoStepIcon/components';
import useStyles from './styles';
import type { PreLoanFormProps } from '../../../../types/preloan';
import { FormSchemaV3 } from '../../../../types/preloan/v3';
import { BricsaButton, GoPlaceItButton } from '../../../../components/PartnerButtons';

export default function PreLoanForm(props:PreLoanFormProps) {
  const {
    preLoanRequestId, preLoanRut, origin, amount, periods, installment, referralCode, optIn,
  } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [currentData, setCurrentData] = React.useState<FormSchemaV3>(
    {
      ...formSchema,
      id: preLoanRequestId,
      rut: preLoanRut,
      origin: origin.originLabel,
      requestedAmount: +amount,
      requestedPeriods: +periods,
      requestedInstallment: +installment,
      referralCode,
      optIn,
      partner: origin.partner,
    },
  );

  const handleCurrentData = (data: FormSchemaV3) => setCurrentData(data);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleStep = (step: number) => setActiveStep(step);

  const [enableLoading, setEnableLoading] = React.useState(false);

  const handleEnableLoading = (state: boolean) => setEnableLoading(state);

  const rejectedFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const rutFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const dataFetchingFormRef = React.useRef <FormikProps<FormSchemaV3>>(null);
  const claveUnicaFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const uploadDocumentsFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const fillFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const loanSimulationFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const companyInfoFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const loanConfirmationRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const needsICPRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const lastStepRef = React.useRef<FormikProps<FormSchemaV3>>(null);

  const refsObject = {
    RejectedFormRef: rejectedFormRef,
    RutFormRef: rutFormRef,
    DataFetchingFormRef: dataFetchingFormRef,
    ClaveUnicaFormRef: claveUnicaFormRef,
    UploadDocumentsFormRef: uploadDocumentsFormRef,
    FillFormRef: fillFormRef,
    CompanyInfoFormRef: companyInfoFormRef,
    LoanSimulationFormRef: loanSimulationFormRef,
    LoanConfirmationRef: loanConfirmationRef,
    NeedsICPRef: needsICPRef,
  };

  const onSubmit = () => {
    if (rejectedFormRef.current) {
      rejectedFormRef.current.handleSubmit();
    }
    if (rutFormRef.current) {
      rutFormRef.current.handleSubmit();
    }
    if (dataFetchingFormRef.current) {
      dataFetchingFormRef.current.handleSubmit();
    }
    if (claveUnicaFormRef.current) {
      claveUnicaFormRef.current.handleSubmit();
    }
    if (uploadDocumentsFormRef.current) {
      uploadDocumentsFormRef.current.handleSubmit();
    }
    if (fillFormRef.current) {
      fillFormRef.current.handleSubmit();
    }
    if (loanSimulationFormRef.current) {
      loanSimulationFormRef.current.handleSubmit();
    }
    if (loanConfirmationRef.current) {
      loanConfirmationRef.current.handleSubmit();
    }
    if (companyInfoFormRef.current) {
      companyInfoFormRef.current.handleSubmit();
    }
    if (lastStepRef.current) {
      lastStepRef.current.handleSubmit();
    }
    if (needsICPRef.current) {
      needsICPRef.current.handleSubmit();
    }
  };
  React.useEffect(() => {
    if (preLoanRequestId.length > 0) {
      handleEnableLoading(true);
      handleStep(1);
    }
  }, [preLoanRequestId]);

  Sentry.setContext(formSchema.product, {
    ...currentData,
    activeStep,
  });
  const handleBackSteps = (step: number) => {
    switch (step) {
      case 2:
        return handleStep(0);
      case 7:
        return handleStep(5);
      default:
        return handleBack();
    }
  };

  const getButton = (plrPartner: string, step:number) => {
    if (step === -1 && plrPartner === 'Banca.Me') {
      return undefined;
    }
    switch (plrPartner) {
      case 'Bricsa':
        return (
          <BricsaButton
            className={classes.submitButton}
            data-pw="preloan-submit-button"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={enableLoading || [2].includes(activeStep)}
          >
            {getButtonLabel(activeStep, currentData.origin)}
          </BricsaButton>
        );
      case 'Go Place It':
        return (
          <GoPlaceItButton
            className={classes.submitButton}
            data-pw="preloan-submit-button"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={enableLoading || [2].includes(activeStep)}
          >
            {getButtonLabel(activeStep, currentData.origin)}
          </GoPlaceItButton>
        );
      default:
        return (
          <Button
            className={classes.submitButton}
            data-pw="preloan-submit-button"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={enableLoading || [2].includes(activeStep)}
          >
            {getButtonLabel(activeStep, currentData.origin)}
          </Button>
        );
    }
  };

  return (
    <Fade
      in
      {...({ timeout: 1000 })}
    >
      <div style={{ width: '100%' }}>
        <br />
        <Typography
          className={classes.title}
          fontWeight="bold"
          variant="h6"
          component="div"
          align="center"
          gutterBottom
        >
          Evaluación crediticia
        </Typography>
        <Stepper
          className={classes.stepper}
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {stepperSteps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon} />
            </Step>
          ))}
        </Stepper>
        <Paper className={classes.paper} elevation={3} style={{ borderRadius: '10px', marginInline: 'auto' }}>
          {activeStep === steps.length ? (
            <LastStepForm
              formRef={lastStepRef}
              formSchema={currentData}
              handleNext={handleNext}
              handleEnableLoading={handleEnableLoading}
              handleStep={handleStep}
              handleCurrentData={handleCurrentData}
            />
          )

            : getStepContent(
              activeStep,
              currentData,
              refsObject,
              handleCurrentData,
              handleNext,
              handleStep,
              handleEnableLoading,
            )}
        </Paper>
        {getButton(currentData.partner, activeStep)}
        <div>
          <Button
            className={classes.backButton}
            disabled={[-1, 0, 1, 2, 3, 4, 5, 6, 8, 9].includes(activeStep) || enableLoading}
            color="primary"
            onClick={() => handleBackSteps(activeStep)}
          >
            Volver
          </Button>
        </div>
      </div>
    </Fade>
  );
}
