/* eslint-disable camelcase */
import React from 'react';
import { CardPayment, StatusScreen } from '@mercadopago/sdk-react';
import * as Sentry from '@sentry/react';
import { ICardPaymentFormData, ICardPaymentBrickPayer } from '@mercadopago/sdk-react/bricks/cardPayment/type';
import { Fade, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import BancameBackdrop from '../BancameBackdrop';
import BackButton from '../BackButton';
import type { MELICardPaymentBrickType } from './types';
import useFBPixeleventTracker from '../../utils/useFBPixelEventTracker';

export default function MELICardPaymentBrick(props: MELICardPaymentBrickType) {
  const {
    token,
    title,
    initialization,
    paymentMethods,
    productId,
    redirectURL,
    returnURL,
    successRedirectLabels,
    postMELIPayment,
  } = props;
  const customization = {
    paymentMethods,
  };
  const history = useHistory();
  const theme = useTheme();
  const [backdropType, setBackdropType] = React.useState<'loading' | 'error'>('loading');
  const [openBackdrop, setOpenBackdrop] = React.useState(true);
  const [paymentId, setPaymentId] = React.useState<string | undefined>(undefined);
  const [paymentSuccess, setPaymentSuccess] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const onReady = () => {
    if (backdropType === 'loading') {
      setOpenBackdrop(false);
    }
  };
  const redirectOnSubmit = () => setTimeout(() => {
    window.location.href = redirectURL;
  }, 7000);
  const returnAction = () => {
    history.push(returnURL);
  };
  const onPaymentReady = () => {
    redirectOnSubmit();
    setShowMessage(true);
  };
  const onError = async (error: any) => {
    window.dataLayer.push({
      event: 'Error',
      paso: `Pago de ${title.toLowerCase()}`,
      mensaje: 'MELI brick error',
    });
    Sentry.setContext('MercadoPago Service', {
      error,
    });
    setBackdropType('error');
  };

  const onSubmit = async (formData: ICardPaymentFormData<ICardPaymentBrickPayer>) => {
    try {
      const res = await postMELIPayment(formData, productId, token);
      if (res.data.mercadoLibreResponse.status === 'approved') {
        setPaymentSuccess(true);
        useFBPixeleventTracker('Informe_deuda', { event: 'Pago_exitoso' });
      }
      setPaymentId(res.data.mercadoLibreResponse.id);
      if (res.data.mercadoLibreResponse.status === 'approved') {
        window.dataLayer.push({
          event: `Solicitud de ${title.toLowerCase()}`,
          paso: 'Pago exitoso',
        });
      } else {
        window.dataLayer.push({
          event: 'Error',
          paso: `Pago de ${title.toLowerCase()}`,
          mensaje: res.data.mercadoLibreResponse.status,
        });
      }
    } catch (e) {
      window.dataLayer.push({
        event: 'Error',
        paso: `Pago de ${title.toLowerCase()}`,
        mensaje: 'Payment backend error',
      });
      Sentry.setContext('MercadoPago Backend Endpoint', {
        error: e,
      });
      setBackdropType('error');
      setOpenBackdrop(true);
    }
  };

  return (
    <div>
      <Typography
        align="center"
        component="div"
        color="white"
        fontWeight="bold"
        marginTop={3}
        marginBottom={3}
        width="100%"
        variant="h6"
      >
        {title}
        <div>{`$${initialization.amount.toLocaleString('es-CL')}`}</div>
      </Typography>
      <div style={{ display: paymentId ? 'none' : undefined }}>
        <CardPayment
          initialization={initialization}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
          customization={customization}
        />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <BackButton
            label={<b style={{ color: theme.customPalette.tertiary.main }}>Volver</b>}
            lightMode
            onClickAction={returnAction}
          />
        </div>
      </div>
      {paymentId
          && (
          <>
            {paymentSuccess && (
            <Typography
              color="white"
              variant="h5"
              fontWeight="bold"
              marginTop={10}
            >
              {showMessage && '¡Tu pago fue aceptado!'}
            </Typography>
            )}
            <Fade
              in
              easing={{
                enter: 'cubic-bezier(1.0, 0.0, 1.0, 0.0)',
              }}
              {... ({ timeout: 4200 })}
            >
              <div style={{ marginTop: '20px' }}>
                <StatusScreen
                  initialization={{ paymentId }}
                  onReady={onPaymentReady}
                />
              </div>
            </Fade>
            {!paymentSuccess && showMessage
              && (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <BackButton
                    label={(
                      <b
                        style={{ color: theme.customPalette.tertiary.main }}
                      >
                        Volver a Productos
                      </b>
)}
                    lightMode
                    onClickAction={returnAction}
                    maxWidth={400}
                  />
                </div>
              )}
          </>
          )}
      <BancameBackdrop
        type={backdropType}
        openBackdrop={openBackdrop}
        loadingText={!paymentId ? 'Cargando...' : successRedirectLabels(redirectURL)}
        errorRedirectURL={returnURL}
      />
    </div>
  );
}

MELICardPaymentBrick.defaultProps = {
  paymentMethods: {
    minInstallments: 1,
    maxInstallments: 1,
  },
};
