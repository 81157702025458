import React from 'react';
import {
  Typography, Link, FormControlLabel, Checkbox,
} from '@mui/material';
import useStyles from './styles';

type TermsAndConditionsLinkType = {
  origin: string,
  checked: boolean,
  handleSetChecked: () => void,
}

export default function TermsAndConditionsLink(props: TermsAndConditionsLinkType) {
  const classes = useStyles();
  const { origin, checked, handleSetChecked } = props;
  return (
    <FormControlLabel
      style={{ backgroundColor: 'whitesmoke', padding: 10, borderRadius: 20 }}
      control={(
        <Checkbox
          size="small"
          checked={checked}
          onClick={handleSetChecked}
          data-pw="preloan-default-optIn"
        />
          )}
      sx={{ marginLeft: '1em', marginRight: '1em', maxWidth: 650 }}
      label={(
        <Typography className={classes.termsAndConditions} align="left" variant="body2">
          Acepto los
          {' '}
          <Link style={{ textDecoration: 'underline' }} href={`/terms-and-conditions?origin=${origin}`} target="_blank" rel="noopener">
            términos y condiciones
          </Link>
          {' '}
          y
          {' '}
          <Link style={{ textDecoration: 'underline' }} href="https://www.banca.me/politicas-de-privacidad" target="_blank" rel="noopener">
            política de privacidad
          </Link>
          , lo que incluye la consulta de cuentas y deudas asociadas a mi RUT
        </Typography>
          )}
    />

  );
}
