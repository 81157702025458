import React from 'react';
import {
  Button,
  Dialog,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import PrepayOptions from './prepayOptions';
import useStyles from './styles';
import type { PrepayDialogProps } from '../../../types/account';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

export default function PrepayDialog(props: PrepayDialogProps) {
  const {
    unpaidAmount, paidAmount, token, hasDelinquency, productType, loanId,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState<string|null>(null);

  const handleClickOpen = (type: string) => {
    if (type === 'prepay') {
      window.dataLayer.push({ event: 'Navegación', boton: 'Gestionar' });
    }
    setContent(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {productType !== 'downPayment'
      && (
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClickOpen('prepay')}
        sx={{ margin: { xs: 2, lg: 'auto 5px' } }}
      >
        Gestionar
      </Button>
      )}
      <Dialog
        className={classes.Dialog}
        open={open}
        TransitionComponent={Transition}
        style={{ textAlign: 'center', margin: 0 }}
        maxWidth="lg"
      >
        {content === 'prepay' && (
          <PrepayOptions
            handleClose={handleClose}
            unpaidAmount={unpaidAmount}
            paidAmount={paidAmount}
            hasDelinquency={hasDelinquency}
            productType={productType}
            token={token}
            loanId={loanId}
          />
        )}

      </Dialog>
    </>
  );
}
