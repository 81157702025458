import React from 'react';
import { Typography, useTheme } from '@mui/material';
import AlertDialog from '../../../../../../../components/AlertDialog';
import avatarWhistle from '../../../../../../../assets/avatar_whistle_2.svg';
import { FormSchemaV3 } from '../../../../../../../types/preloan/v3';

type ConfirmationDialogProps<FormSchema> ={
    formSchema: FormSchema,
    isTriggered: boolean,
    handleSubmit: ()=> void | Promise<void>,
}

export default function ConfirmationDialog
<T extends FormSchemaV3>(props:ConfirmationDialogProps<T>) {
  const {
    formSchema,
    isTriggered,
    handleSubmit,
  } = props;
  const theme = useTheme();

  const productName = () => {
    if (formSchema.requestLoanData.periods < 2) {
      return 'adelanto';
    }
    return 'crédito';
  };

  return (
    <AlertDialog
      dialogContent={(
        <div>
          <div style={{ textAlign: 'center' }}>
            <object
              data={avatarWhistle}
              aria-labelledby="Avatar"
              height="120px"
            />
          </div>
          <Typography
            fontWeight="bold"
            variant="h5"
            align="center"
            gutterBottom
          >
            ¡Todo en orden!

          </Typography>
          <br />
          {formSchema.step === 'LOAN_SIMULATION'
                  && (
                  <Typography variant="body1" style={{ maxWidth: '400px' }}>
                    Al presionar el botón azul
                    {' '}
                    <b style={{ color: theme.palette.secondary.main }}>
                      se redirigirá a
                      firmar una solicitud de
                      {' '}
                      {productName()}
                    </b>
                    ,
                    por lo que
                    {' '}
                    <b>
                      no hay posibilidad de retorno
                    </b>
                    {' '}
                    a la simulación del
                    {' '}
                    {productName()}
                    .
                  </Typography>
                  )}
        </div>
            )}
      action={handleSubmit}
      button={<div />}
      isTriggered={isTriggered}
      submitButtonLabel={formSchema.step === 'READY_FOR_LOAN' ? 'Continuar' : 'Ir a firmar'}
    />

  );
}
