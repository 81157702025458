import { resultsLabels } from './ResultsLabels';
import type { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import { Results } from '../../../../../../../types/preloan';

export default function sendRiskEngineResultsToGTM(params:{
  origin: {originLabel: FormSchemaV3['origin'],
  partner: FormSchemaV3['partner'],
  partnerProducts: FormSchemaV3['partnerProducts']},
  results: Results[],
  paso: 'ClaveÚnica'|'Carga de documentos'|'Datos complementarios',
  subpaso: 'Ingreso de ClaveÚnica'|'Carga de última liquidación'|'Carga de últimas 24 cotizaciones'|'Carga de última liquidación y últimas 24 cotizaciones'|'Completar formulario',
}) {
  const { origin, results, subpaso } = params;
  return results && results.length > 0 && results.forEach((result) => {
    Object.entries(result.hardFilterResults).forEach((fr) => {
      if (!fr[1]) {
        window.dataLayer.push({
          event: 'Usuario rechazado',
          paso: 'ClaveÚnica',
          subpaso,
          motivo: resultsLabels(origin)[fr[0]],
          producto: result.productType,
          forma_cobro: result.paymentMethod,
        });
      }
    });
  });
}
