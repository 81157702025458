import * as React from 'react';
import {
  Button, Checkbox, FormControlLabel, Typography, TextField,
} from '@mui/material';
import {
  Formik, Form, getIn,
} from 'formik';
import AlertDialog from '../../../components/AlertDialog';
import avatarSmiling from '../../../assets/avatar_smiling.svg';
import useStyles from './styles';
import type { FormSchemaV3 } from '../../../types/preloan/v3';
import validationSchema from './validationSchema';
import { formInputs, handleInputSetter } from './inputsData';
import bancameLogo from '../../../assets/bancame_logo_sans.svg';

type ContactFormProps = {
  formSchema: FormSchemaV3,
  onSubmit: (values: FormSchemaV3['contactForm']) => void,
  onOpenDatalayer: {event: string, paso:string, subpaso: string},
  handleCloseContactForm: ()=> void;
  triggerOpen: boolean;
}

export default function ContactForm(props: ContactFormProps) {
  const {
    formSchema,
    onSubmit,
    onOpenDatalayer,
    handleCloseContactForm,
    triggerOpen,
  } = props;
  const classes = useStyles();
  const scrollRef = React.useRef<null | HTMLDivElement>(null);
  const [triggerClose, setTriggerClose] = React.useState(false);
  return (
    <Formik
      initialValues={formSchema}
      validationSchema={() => validationSchema(Object.keys(formSchema.contactForm))}
      onSubmit={(values) => {
        setTriggerClose(true);
        setTriggerClose(false);
        return onSubmit(values.contactForm);
      }}
    >
      {({
        values,
        handleBlur,
        setFieldValue,
        touched,
        errors,
        isSubmitting,
        handleSubmit,
      }) => (
        <AlertDialog
          dialogContent={(
            <div style={{ textAlign: 'center' }}>
              <div>
                <object
                  data={avatarSmiling}
                  width="60px"
                  aria-labelledby="Avatar Similing"
                />
              </div>
              <Typography
                data-pw="contact-form-dialog-title"
                fontWeight="bold"
                variant="h6"
                gutterBottom
              >
                Necesitamos confirmar los siguientes datos

              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                align="center"
                maxWidth={800}
                margin="auto"
              >
                {Object.values(values.contactForm)
                  .some((contactInfo) => contactInfo.value.includes('**')) ? (
                    `Extrajimos la siguiente información de tu contacto. 
                En el caso de que no tengas seguridad sobre los datos ocultos,
                siempre puedes volver a colocarlos en este formulario
                `
                  ) : 'Nos faltan los siguientes datos de contacto para continuar con tu evaluación'}

              </Typography>
              <Form
                className={classes.form}
                style={{ gridTemplateColumns: Object.keys(values.contactForm).length === 1 ? '1fr' : undefined }}
              >
                {isSubmitting && scrollRef.current && scrollRef.current.scrollIntoView({ behavior: 'smooth' })}
                {Object.entries(values.contactForm)
                  .map((zi) => (
                    <div key={zi[0]}>
                      <Typography
                        gutterBottom
                        color="text.secondary"
                        variant="body2"
                      >
                        {formInputs[zi[0]].title}

                      </Typography>

                      <TextField
                        className={classes.formControl}
                        disabled={values.contactForm[zi[0] as keyof typeof values.contactForm]
                          .ok}
                        placeholder={formInputs[zi[0]].placeholder}
                        value={values.contactForm[zi[0] as keyof typeof values.contactForm]
                          .value}
                        onChange={(event) => handleInputSetter(
                          zi[1].type,
                          setFieldValue,
                          `contactForm.${zi[0]}.value`,
                          event.target.value,
                        )}
                        onBlur={handleBlur}
                        id={`contactForm.${zi[0]}.value`}
                        name={`contactForm.${zi[0]}.value`}
                        helperText={getIn(touched, `contactForm.${zi[0]}.value`)
                          ? getIn(errors, `contactForm.${zi[0]}.value`) : ''}
                        error={getIn(touched, `contactForm.${zi[0]}.value`)
                              && Boolean(getIn(errors, `contactForm.${zi[0]}.value`))}
                      />
                      <FormControlLabel
                        control={(
                          <Checkbox
                            id={`contactForm.${zi[0]}.ok`}
                            name={`contactForm.${zi[0]}.ok`}
                            size="small"
                            checked={!values.contactForm[zi[0] as keyof typeof values.contactForm]
                              .ok}
                            onClick={() => {
                              if (!values.contactForm[zi[0] as keyof typeof values.contactForm]
                                .ok === true) {
                                setFieldValue(
                                  `contactForm.${zi[0]}.value`,
                                  formSchema.contactForm[zi[0] as keyof typeof values.contactForm]
                                    .value,
                                );
                              } else {
                                setFieldValue(
                                  `contactForm.${zi[0]}.value`,
                                  formInputs[zi[0]].defaultValue,
                                );
                              }
                              setFieldValue(
                                `contactForm.${zi[0]}.ok`,
                                !values.contactForm[zi[0] as keyof typeof values.contactForm]
                                  .ok,
                              );
                            }}
                          />
                             )}
                        label={(
                          <Typography variant="body2" width={200}>
                            {!values.contactForm[zi[0] as keyof typeof values.contactForm]
                              .ok ? 'Revertir cambios' : 'Quiero modificar este campo'}
                          </Typography>
                             )}
                      />
                    </div>

                  ))}

              </Form>
            </div>
      )}
          maxWidth="md"
          button={(
            <Button
              variant="outlined"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <object
                data={bancameLogo}
                aria-labelledby="Banca.Me logo"
                height="30px"
                style={{ marginRight: 10, marginBottom: '-5px' }}
              />
              <div>
                Datos de contacto
              </div>
              <div />
            </Button>
      )}
          action={handleSubmit}
          onCloseAction={handleCloseContactForm}
          isTriggered={triggerOpen}
          submitButtonLabel="CONFIRMAR"
          triggerClose={triggerClose}
          dataLayer={onOpenDatalayer}
        />
      )}
    </Formik>
  );
}
