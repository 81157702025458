import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { FormikProps } from 'formik';
import avatarSmiling from '../../../assets/avatar_smiling.svg';
import contentSwitch from './content';
import useStyles from './styles';
import type { PLRAlertState } from '../../../types/preloan';
import type { FormSchemaV3 } from '../../../types/preloan/v3';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

type PLRAlertDialogProps = {
  alertState: PLRAlertState,
  email: string,
  formSchema: FormSchemaV3,
  handlePostPersonalInfo: (personalInfo: {
    firstName: string,
    lastName: string,
    birthDate: Date | null,
    sex: string
  })=> void,
}

export default function PLRAlertDialog(props: PLRAlertDialogProps) {
  const {
    alertState, email, formSchema, handlePostPersonalInfo,
  } = props;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const classes = useStyles();
  const [disableButton, setDisableButton] = React.useState(false);

  const personalInfoFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);

  React.useEffect(() => { if (alertState) { handleClickOpen(); } }, [alertState]);

  const handleClose = () => {
    setOpen(false);
  };

  const submitPersonalInfo = () => {
    setDisableButton(true);
    setTimeout(() => {
      if (personalInfoFormRef.current) {
        personalInfoFormRef.current.handleSubmit();
        setDisableButton(false);
      }
    }, 1000);
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
      >
        Ver detalle

      </Button>
      <Dialog
        className={classes.dialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >

        <div style={{ textAlign: 'center', marginTop: 15 }}>
          {' '}
          <object
            data={avatarSmiling}
            aria-labelledby="AvatarSmiling"
            width="80px"
          />
          <DialogTitle style={{ fontWeight: 'bold', padding: 0 }}>
            {contentSwitch(
              alertState,
              email,
              formSchema,
              handlePostPersonalInfo,
              personalInfoFormRef,
            ).title}
          </DialogTitle>
        </div>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            color="primary"
            component="div"
            textAlign="justify"
          >
            {contentSwitch(
              alertState,
              email,
              formSchema,
              handlePostPersonalInfo,
              personalInfoFormRef,
            ).content}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={disableButton}
          >
            Cerrar

          </Button>
          {alertState === 'userHasMissingPersonalInfo'
            && (
            <Button
              style={{ maxWidth: 150 }}
              variant="contained"
              onClick={submitPersonalInfo}
              disabled={disableButton}
            >
              Enviar
            </Button>
            )}
        </DialogActions>
      </Dialog>
    </>
  );
}
