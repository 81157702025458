import React from 'react';
import { Button } from '@mui/material';
import { useIntercom } from 'react-use-intercom';
import bancameLogo from '../../assets/bancame_logo_sans.svg';

type AgentContactButtonProps = {
  size?: 'small' | 'normal',
  dataLayerStep: string,
  hide?: boolean
}

const defaultProps = {
  size: 'normal',
  hide: false,
};

export default function AgentContactButton(props: AgentContactButtonProps) {
  const {
    size, dataLayerStep, hide,
  } = props;
  const { show: showIntercom } = useIntercom();
  const handleOpenIntercom = () => {
    window.dataLayer.push({
      event: 'Acciones',
      paso: dataLayerStep,
      accion: 'Contactar a un agente',
    });

    showIntercom();
  };
  return (
    hide
      ? <div />
      : (
        <Button
          onClick={handleOpenIntercom}
          variant="outlined"
          startIcon={(
            <object
              data={bancameLogo}
              aria-labelledby="Banca.Me logo"
              height={size === 'small' ? 23 : '30px'}
              style={{ marginBottom: '-5px' }}
            />
      )}
          style={{ fontSize: size === 'small' ? 13 : undefined }}
        >
          Contactar a un agente

        </Button>
      )
  );
}

AgentContactButton.defaultProps = defaultProps;
