import React from 'react';
import { Formik, Form } from 'formik';
import {
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import type { PLRFormProps } from '../../../../../../types/preloan';
import { FormSchemaV3 } from '../../../../../../types/preloan/v3';
import avatarConfident from '../../../../../../assets/avatar_finger.svg';
import DataForm from '../../../../DataForm';
import { patchFillForm, postPreApprove } from '../../../../../../queries/preloan/v3';
import { getInterestRate } from '../../../../../../queries/preloan';
import useFBPixeleventTracker from '../../../../../../utils/useFBPixelEventTracker';
import sendRiskEngineResultsToGTM from '../00_rejected/components/sendRiskEngineResultsToGTM';
import ContactForm from '../../../../ContactForm';
import { archiveUnemployedPLR, partnerErrorRedirectURL } from '../../formLogic';

export default function FillForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
    handleCurrentData,
    handleStep,
  } = props;
  const [contactFormOk, setContactFormOk] = React.useState(false);
  const [dataFormOk, setDataFormOk] = React.useState(false);
  const [contactFormData, setContactFormData] = React.useState(formSchema.contactForm);
  const [dataFormData, setDataFormData] = React.useState(formSchema.dataForm);
  const [error, setError] = React.useState(false);
  const handleSubmitDataForm = (values: FormSchemaV3['dataForm']) => {
    setDataFormOk(true);
    setDataFormData(values);
  };
  const handleCloseDataForm = () => setDataFormOk(false);
  const handleSubmitContactForm = (values: FormSchemaV3['contactForm']) => {
    setContactFormOk(true);
    setContactFormData(values);
  };
  const handleCloseContactForm = () => setContactFormOk(false);

  React.useEffect(() => {
    if (error) {
      const redirectURL = partnerErrorRedirectURL(formSchema.origin, formSchema.rut);
      if (redirectURL) {
        setTimeout(() => { window.location.href = redirectURL; }, 1000);
      }
    }
  }, [error]);

  const hasContactFormData = Object.keys(formSchema.contactForm).length > 0;
  const hasDataFormData = Object.keys(formSchema.dataForm).length > 0;

  const [triggerOpenContactForm, setTriggerOpenContactForm] = React.useState(false);
  const [triggerOpenDataForm, setTriggerOpenDataForm] = React.useState(false);

  React.useEffect(() => {
    if (hasContactFormData && hasDataFormData) {
      if (contactFormOk && dataFormOk) {
        return handleEnableLoading(false);
      } if (contactFormOk) {
        setTimeout(() => setTriggerOpenDataForm(true), 500);
      } else if (dataFormOk) {
        setTimeout(() => setTriggerOpenContactForm(true), 500);
      }
    } else if (hasContactFormData) {
      if (contactFormOk) {
        return handleEnableLoading(false);
      }
    } else if (hasDataFormData) {
      if (dataFormOk) {
        return handleEnableLoading(false);
      }
    }
    return handleEnableLoading(true);
  }, [contactFormOk, dataFormOk]);

  React.useEffect(() => {
    setTimeout(() => {
      if (hasContactFormData) {
        setTriggerOpenContactForm(true);
      } else if (hasDataFormData) {
        setTriggerOpenDataForm(true);
      }
    }, 500);
  }, []);

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={async (values) => {
        try {
          window.dataLayer.push({
            event: 'Proceso onboarding',
            paso: 'Datos complementarios',
            subpaso: 'Completar formulario',
          });
          handleEnableLoading(true);
          const parsedQueryData = {} as {[key:string]: string|number};
          Object.keys(contactFormData).forEach((key) => {
            parsedQueryData[key] = contactFormData[key]?.value.trim();
          });
          Object.keys(dataFormData).forEach((key) => {
            parsedQueryData[key] = dataFormData[key]?.type === 'number'
              ? +(dataFormData[key].value.trim())
              : dataFormData[key]?.value.trim();
          });
          const { data } = await patchFillForm(values.PLRtoken, parsedQueryData);
          if (dataFormData.type_of_contract?.value === 'DESEMPLEADO') {
            return archiveUnemployedPLR(formSchema, handleCurrentData, handleStep);
          }

          switch (data.plr.step) {
            case 'DATA_OK': {
              const { data: preApproveData } = await postPreApprove(formSchema.PLRtoken);
              switch (preApproveData.plr.step) {
                case 'LOAN_SIMULATION': {
                  const interestRate = await getInterestRate(formSchema.PLRtoken);
                  handleCurrentData({
                    ...formSchema,
                    step: preApproveData.plr.step,
                    loanParams: interestRate.data,
                  });
                  return handleStep(5);
                }
                case 'READY_FOR_LOAN': {
                  handleCurrentData({
                    ...formSchema,
                    step: preApproveData.plr.step,
                    preApprove: {
                      accepted: preApproveData.preApprove?.accepted,
                      maxAmount: preApproveData.preApprove?.maxAmount,
                      productType: preApproveData.preApprove?.productType,
                      rate: preApproveData.preApprove?.rate,
                      maxPeriods: preApproveData.preApprove?.maxPeriods,
                    },
                  });
                  return handleStep(9);
                }
                case 'ARCHIVED_FAIL': {
                  useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso ClaveÚnica a Rechazo del lead' });
                  handleCurrentData({
                    ...formSchema,
                    step: preApproveData.plr.step,
                    firstName: preApproveData.plr.firstName,
                    lastName: preApproveData.plr.lastName,
                    rut: preApproveData.plr.idNumber,
                    results: preApproveData.results,
                    origin: preApproveData.plr.origin,
                    adminComment: preApproveData.plr.adminComment,
                  });
                  sendRiskEngineResultsToGTM({
                    origin: {
                      originLabel: formSchema.origin,
                      partner: formSchema.partner,
                      partnerProducts: formSchema.partnerProducts,
                    },
                    results: preApproveData.results,
                    paso: 'Datos complementarios',
                    subpaso: 'Completar formulario',
                  });
                  return handleStep(['conquista', 'buyDepa', 'norteVerde', 'goplaceit'].includes(formSchema.origin) ? 9 : -1);
                }
                default:
                  return setError(true);
              }
            }
            case 'ARCHIVED_FAIL': {
              useFBPixeleventTracker(formSchema.product, { event: 'Redirección desde ingreso ClaveÚnica a Rechazo del lead' });
              handleCurrentData({
                ...formSchema,
                adminComment: data.plr.adminComment,
                firstName: data.plr.firstName,
                lastName: data.plr.lastName,
                rut: data.plr.idNumber,
                origin: data.plr.origin,
              });
              window.dataLayer.push({
                event: 'Usuario rechazado', paso: 'Datos complementarios', subpaso: 'Completar formulario', motivo: data.plr.adminComment,
              });
              return handleStep(['conquista', 'buyDepa', 'norteVerde', 'goplaceit'].includes(formSchema.origin) ? 9 : -1);
            }
            case 'READY_FOR_LOAN': {
              handleCurrentData({
                ...formSchema,
                step: data.plr.step,
              });
              return handleStep(9);
            }
            default:
              return setError(true);
          }
        } catch (e) {
          return setError(true);
        }
      }}
    >
      {({
        values,
        isSubmitting,
      }) => (

        <Form>
          <br />
          <div>
            <object data={avatarConfident} aria-labelledby="Avatar" height="80px" />
          </div>

          <Typography
            gutterBottom
            variant="h6"
            fontWeight="bold"
            align="center"
            alignSelf="center"
          >
            Confirmar datos

          </Typography>
          <Divider />
          <br />
          <Typography>
            ¡Aún nos falta información para tu evaluación!
            {' '}
            <b>
              Por favor confirma los datos solicitados a continuación
            </b>
            {' '}
            para poder evaluarte oportunamente
          </Typography>
          <br />
          {hasContactFormData
          && (
          <div style={{
            display: 'flex',
            placeContent: 'center',
            alignItems: 'center',
            paddingLeft: 40,
          }}
          >
            <ContactForm
              onSubmit={handleSubmitContactForm}
              handleCloseContactForm={handleCloseContactForm}
              formSchema={values}
              triggerOpen={triggerOpenContactForm}
              onOpenDatalayer={{
                event: 'Proceso onboarding',
                paso: 'Datos de contacto',
                subpaso: 'Ingresar a formulario',
              }}
            />
            <div style={{ width: 50, paddingTop: 5 }}>
              {contactFormOk && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
            </div>
          </div>
          )}
          <br />
          {hasDataFormData
          && (
          <div style={{
            display: 'flex',
            placeContent: 'center',
            alignItems: 'center',
            paddingLeft: 40,
          }}
          >
            <DataForm
              onSubmit={handleSubmitDataForm}
              handleCloseDataForm={handleCloseDataForm}
              formSchema={values}
              triggerOpen={triggerOpenDataForm}
              onOpenDatalayer={{
                event: 'Proceso onboarding',
                paso: 'Datos complementarios',
                subpaso: 'Ingresar a formulario',
              }}
            />
            <div style={{ width: 50, paddingTop: 5 }}>
              {dataFormOk && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
            </div>
          </div>
          )}
          <br />
          <Divider />
          {error
          && (
          <Typography
            variant="caption"
            color="error"
          >
            Ha ocurrido un error. Por favor contáctate con nosotros.
          </Typography>
          )}
          <br />
          {isSubmitting
                && (
                <Typography>
                  Cargando...
                  {' '}
                  <CircularProgress size={10} />
                </Typography>
                )}
        </Form>
      )}

    </Formik>
  );
}
