import React from 'react';
import {
  Card,
  Grid,
  Fade,
  Typography,
} from '@mui/material';
import useStyles from './styles';
import avatarConfident from '../../../assets/avatar_confident.svg';
import ViewTitle from '../../../components/ViewTitle/index';

type InvestmentMenuProps = {
    investment: number,
}

function InvestmentOverview({ investment }: InvestmentMenuProps) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      rowGap={5}
    >
      <ViewTitle
        title="Mi Inversión"
        subtitle="En esta sección podrás ver tu inversión acumulada con Banca.Me."
        color="secondary"
        variant="h4"
      />
      <Fade in {... ({ timeout: 2500 })}>
        <Grid
          item
          xs={12}
          lg={11}
        >
          <Card
            sx={{ borderRadius: '15px' }}
            className={classes.referralOverview}
          >
            <div style={{ margin: 20 }}>
              <Typography
                variant="h5"
                gutterBottom
                style={{ margin: '3%' }}
              >
                Tienes invertido un total de:
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color="secondary"
                style={{ margin: '3%' }}
              >
                $
                {investment.toLocaleString('es-CL')}
              </Typography>
            </div>
            <div style={{ textAlign: 'center', marginBlock: '5%' }}>
              <object
                data={avatarConfident}
                width="50px"
                aria-labelledby="Bancamin"
              />
              <Typography
                align="center"
                color="secondary"
                fontWeight="bold"
                style={{ maxWidth: 300, margin: 'auto' }}
              >
                Recuerda que esta inversión la puedes retirar una vez hayas pagado tu crédito.
                Si te encuentras moroso ¡Paga tu deuda para recibir tu inversión!
              </Typography>
            </div>
          </Card>
        </Grid>
      </Fade>

    </Grid>
  );
}

export default InvestmentOverview;
