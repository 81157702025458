/* eslint-disable import/prefer-default-export */
import { ClaveUnicaEndpointProps } from '../../../components/ClaveUnicaForm';
import { WorkerType } from '../../../feature/preloan/DependentStatusDialog';
import { PostExperianChallengeResponse } from '../../../feature/preloan/v3/PreLoanForm/steps/03_3_NeedsICP/components/ExperianForm/types';
import {
  PostUploadDocumentsResponse,
  PostClaveUnicaResponse,
  PostPreApproveResponse,
  PatchCheckDocumentsResponse,
  PatchFillFormResponse,
  PostCheckoutResponse,
  PatchOriginClickResponse,
} from '../../../types/preloan/v3';
import fetcher from '../../../utils/fetcher';

export async function postClaveUnicaHistory(params: ClaveUnicaEndpointProps):
Promise<PostClaveUnicaResponse> {
  const { token, password } = params;
  const response = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/clave-unica-history`,
    'POST',
    { password },
    token,
  );
  return response;
}
export async function patchClaveUnica(params: {token: string}):
Promise<PostClaveUnicaResponse> {
  const { token } = params;
  const response = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/clave-unica`,
    'PATCH',
    null,
    token,
  );
  return response;
}
export async function patchDependentStatus(params: {token: string, workerType: WorkerType}):
Promise<PostClaveUnicaResponse> {
  const { token, workerType } = params;
  const response = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/dependent-status`,
    'PATCH',
    { workerType },
    token,
  );
  return response;
}
export async function postPreApprove(token: string):
Promise<PostPreApproveResponse> {
  const response = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/pre-approve`,
    'POST',
    null,
    token,
  );
  return response;
}
export async function postUploadDocuments(
  token: string,
  payslipExtension?: string,
  pensionQuoteExtension?: string,
):
Promise<PostUploadDocumentsResponse> {
  const response = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/v2.5/upload-documents`,
    'POST',
    { payslipExtension, pensionQuoteExtension },
    token,
  );
  return response;
}

export async function patchCheckDocuments(token: string, retries: number):
Promise<PatchCheckDocumentsResponse> {
  const response = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/v2.5/check-documents`,
    'PATCH',
    { retries },
    token,
  );
  return response;
}
export async function postDeniedClaveUnicaV3(token: string): Promise<PostClaveUnicaResponse> {
  const res = await fetcher(`${process.env.REACT_APP_API_URL}/pre-loan-request/v2.5/denied-clave-unica`, 'POST', null, token);
  return res;
}
export async function patchFillForm(
  token: string,
  body: {}|null = null,
): Promise<PatchFillFormResponse> {
  const res = await fetcher(`${process.env.REACT_APP_API_URL}/pre-loan-request/v2.5/fill-form`, 'PATCH', body && { params: JSON.stringify(body) }, token);
  return res;
}

export async function postCheckout(token: string):
Promise<PostCheckoutResponse> {
  const response = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/checkout`,
    'POST',
    null,
    token,
  );
  return response;
}

export async function patchOriginClick(originLabel: string, click: boolean)
:Promise<PatchOriginClickResponse> {
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/origin/click`,
    'PATCH',
    { label: originLabel, click },
    null,
  );
  return res;
}

export async function postExperianChallenge(params: {
  serialNumber: string,
  canalInstitucion: string,
  token: string,
})
:Promise<PostExperianChallengeResponse> {
  const {
    serialNumber,
    canalInstitucion,
    token,
  } = params;
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/experian/challenge`,
    'POST',
    {
      numeroSerie: serialNumber,
      canalInstitucion,
    },
    token,
  );
  return res;
}

export async function postExperianChallengeResponses(params: {
  challengeId: string,
  canalInstitucion: string,
  RESPUESTAS: {CODIGO_PREGUNTA: string, CODIGO_RESPUESTA: string}[],
  token: string
})
:Promise<any> {
  const {
    challengeId,
    canalInstitucion,
    RESPUESTAS,
    token,
  } = params;
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/experian/submit`,
    'POST',
    {
      challengeId,
      canalInstitucion,
      desafio: JSON.stringify({
        RESPUESTAS,
      }),
    },
    token,
  );
  return res;
}
